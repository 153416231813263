var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"time-slot scroll-arrow"},[(_vm.date)?_c('div',[(_vm.pagination.noOfPages > 1)?_c('a',{staticClass:"arrow left btn p-0",class:{
      'theme-bg': _vm.pagination.currentPage,
      disabled: _vm.pagination.currentPage === 0,
    },attrs:{"disabled":_vm.pagination.currentPage === 0},on:{"click":function($event){_vm.pagination.currentPage === 0 ? true : (_vm.pagination.currentPage -= 1)}}},[_c('i',{staticClass:"fas fa-caret-left"})]):_vm._e(),(_vm.pagination.noOfPages > 1)?_c('a',{staticClass:"arrow right btn p-0",class:{
      'theme-bg': _vm.pagination.currentPage < _vm.pagination.noOfPages - 1,
      disabled: _vm.pagination.currentPage === _vm.pagination.noOfPages - 1,
    },attrs:{"disabled":_vm.pagination.currentPage === _vm.pagination.noOfPages - 1},on:{"click":function($event){_vm.pagination.currentPage === _vm.pagination.noOfPages - 1
        ? true
        : (_vm.pagination.currentPage += 1)}}},[_c('i',{staticClass:"fas fa-caret-right"})]):_vm._e(),_c('div',{staticClass:"scroll-wrapper"},[_c('div',{staticClass:"time-slot"},_vm._l((_vm.timeSlots[_vm.pagination.currentPage]),function(time,index){return _c('label',{key:index,staticClass:"mx-1",on:{"click":function($event){return _vm.setSlot(time)}}},[_c('div',{staticClass:"item-container"},[_c('div',{staticClass:"item",class:_vm.getSlotClass(time)},[_c('span',{staticClass:"item-label",attrs:{"name":`time-slot-${index}`}},[_c('div',[_vm._v(_vm._s(_vm._f("formatTime")(time)))])])])])])}),0)])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }