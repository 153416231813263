<template>
  <div>
    <div class="preview" ref="preview" :class="{ 'd-none': showPrint }">
      <CCard>
        <CCardBody>
          <CRow class="mb-2">
            <CCol md="6">
              <CRow>
                <label class="col-lg-12 col-md-12">Candidate Name</label>
                <CCol md="12">
                  <TextInput
                    name="full_name"
                    :value="candidateInfo.full_name"
                    :disabled="true"
                    :CSS_Style="disabled_style"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="6">
              <CRow>
                <label class="col-lg-12 col-md-12">Candidate Type</label>
                <CCol md="12">
                  <TextInput
                    name="candidate_type"
                    :value="candidateInfo.candidate_type"
                    :disabled="true"
                    :CSS_Style="disabled_style"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol md="6">
              <CRow>
                <label class="col-lg-12 col-md-12">Current Employer</label>
                <CCol md="12">
                  <TextInput
                    name="current_employer"
                    :value="feedBack.current_employer"
                    :disabled="true"
                    :CSS_Style="disabled_style"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="6">
              <CRow>
                <label
                  class="col-lg-12 col-md-12"
                  v-if="candidateInfo.notice_period_days"
                  >Notice Period ({{ candidateInfo.notice_period_days }} day{{
                    candidateInfo.notice_period_days > 1 ? "s" : ""
                  }})</label
                >
                <label class="col-lg-12 col-md-12" v-else
                  >Notice Period (in days)</label
                >
                <CCol md="12">
                  <v-slider
                    name="notice_period_days"
                    :min="0"
                    :max="90"
                    :value="candidateInfo.notice_period_days"
                    :disabled="true"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol md="6">
              <CRow>
                <label class="col-lg-12 col-md-12">Current Salary</label>
                <CCol md="12">
                  <CRow>
                    <CCol class="pr-0" style="max-width: 70%">
                      <TextInput
                        name="current_salary"
                        :value="candidateInfo.current_salary"
                        :disabled="true"
                        :CSS_Style="disabled_style"
                      />
                    </CCol>
                    <CCol class="p-0 ml-1" style="max-width: 25%">
                      <TextInput
                        name="current_salary_currency"
                        :value="candidateInfo.current_salary_currency"
                        :disabled="true"
                        :CSS_Style="disabled_style"
                      />
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCol>
            <CCol md="6">
              <CRow>
                <label class="col-lg-12 col-md-12">Expected Salary</label>
                <CCol md="12">
                  <CRow>
                    <CCol class="pr-0" style="max-width: 35%">
                      <TextInput
                        name="salary_expectation_min"
                        :value="candidateInfo.salary_expectation_min"
                        :disabled="true"
                        :CSS_Style="disabled_style"
                      />
                    </CCol>
                    _
                    <CCol class="p-0" style="max-width: 35%">
                      <TextInput
                        name="salary_expectation_max"
                        :value="candidateInfo.salary_expectation_max"
                        :disabled="true"
                        :CSS_Style="disabled_style"
                      />
                    </CCol>
                    <CCol class="ml-1 p-0" style="max-width: 20%">
                      <TextInput
                        name="salary_expectation_currency"
                        :value="candidateInfo.salary_expectation_currency"
                        :disabled="true"
                        :CSS_Style="disabled_style"
                      />
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol md="6">
              <CRow>
                <CCol style="max-width: 20%">
                  <CRow>
                    <label class="col-lg-12 col-md-12">Age</label>
                    <CCol md="12">
                      <TextInput
                        name="age"
                        :value="candidateInfo.age"
                        :disabled="true"
                        :CSS_Style="disabled_style"
                      />
                    </CCol>
                  </CRow>
                </CCol>
                <CCol style="max-width: 80%">
                  <CRow>
                    <label class="col-lg-12 col-md-12">Nationality</label>
                    <CCol md="12">
                      <Select
                        name="nationality_names"
                        :value="candidateInfo.nationality_names"
                        :disabled="true"
                        :multiple="true"
                      />
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </CCol>
            <CCol md="3">
              <CRow>
                <label class="col-lg-12 col-md-12">Marital Status</label>
                <CCol md="12">
                  <RadioButton
                    name="marital_status"
                    :value="candidateInfo.marital_status"
                    :options="(options && options['marital_status']) || []"
                    :disabled="true"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="3" v-if="candidateInfo.marital_status === 'Married'">
              <CRow>
                <label class="col-lg-12 col-md-12">Children</label>
                <CCol md="12">
                  <TextInput
                    name="dependents"
                    :value="candidateInfo.dependents"
                    :disabled="true"
                    :CSS_Style="disabled_style"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol md="6">
              <CRow>
                <label class="col-lg-12 col-md-12"
                  >Languages Written/Spoken</label
                >
                <CCol md="12">
                  <Select
                    name="candidate_languages"
                    :value="candidateInfo.candidate_languages"
                    :clearable="false"
                    :multiple="true"
                    :disabled="true"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard class="mt-2">
        <CCardBody>
          <CRow class="mb-2">
            <CCol md="6">
              <CRow>
                <label class="col-lg-12 col-md-12">Interviewer's</label>
                <CCol md="12">
                  <Select
                    name="assigned_to_users_name"
                    :value="actionDetailInfo.assigned_to_users_name"
                    :multiple="true"
                    :disabled="true"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="6">
              <CRow>
                <label class="col-lg-12 col-md-12"
                  >Interviewee's Specialty</label
                >
                <CCol md="12">
                  <Select
                    name="candidate_specialities"
                    :value="candidateInfo.candidate_specialities"
                    :multiple="true"
                    :disabled="true"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol md="6">
              <CRow>
                <label class="col-lg-12 col-md-12">Date/Time</label>
                <CCol md="12">
                  <TextInput
                    name="datetime"
                    :value="actionDetailInfo.datetime"
                    :disabled="true"
                    :CSS_Style="disabled_style"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="6">
              <CRow>
                <label class="col-lg-12 col-md-12">Venue</label>
                <CCol md="12">
                  <TextInput
                    name="venue"
                    :value="feedBack.venue"
                    :disabled="true"
                    :CSS_Style="disabled_style"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol md="6">
              <CRow>
                <label class="col-lg-12 col-md-12">Interview Method</label>
                <CCol md="12">
                  <TextInput
                    name="interview_mode"
                    :value="actionDetailInfo.interview_mode"
                    :disabled="true"
                    :CSS_Style="disabled_style"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="6">
              <CRow>
                <label class="col-lg-12 col-md-12"
                  >Candidate's Current Location</label
                >
                <CCol md="12">
                  <TextInput
                    name="current_country"
                    :value="candidateInfo.current_country"
                    :disabled="true"
                    :CSS_Style="disabled_style"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol md="6">
              <CRow>
                <label class="col-lg-12 col-md-12">Licensure Status</label>
                <CCol md="12">
                  <TextInput
                    name="licenses"
                    :value="candidateInfo.licenses"
                    :disabled="true"
                    :CSS_Style="disabled_style"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="6">
              <CRow>
                <label class="col-lg-12 col-md-12">Source</label>
                <CCol md="12">
                  <TextInput
                    name="source"
                    :value="feedBack.source"
                    :disabled="true"
                    :CSS_Style="disabled_style"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardBody>
          <div class="d-flex align-items-center">
            <h6 class="text-primary">
              Competency
              <CIcon
                name="cil-info"
                class="hinticon-multi-select mx-1"
                v-c-tooltip="
                  '100% - Exceeds Expectations | 75% - Meets Expectations | 50% - Satisfactory | 0% - Does not meet Expectations'
                "
              />
            </h6>
          </div>
          <CRow class="mb-2">
            <CCol md="6">
              <CRow>
                <label class="col-lg-12">Relevant Work Experience</label>
                <CCol md="12">
                  <RadioButton
                    name="relevant_work_exp"
                    :value="feedBack.relevant_work_exp"
                    :options="
                      options && options['competency']
                        ? options['competency']
                        : []
                    "
                    :disabled="true"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="6">
              <CRow>
                <label class="col-lg-12"
                  >Knowledge of specific job skills</label
                >
                <CCol md="12">
                  <RadioButton
                    name="knowledge_spec_job_skills"
                    :value="feedBack.knowledge_spec_job_skills"
                    :options="
                      options && options['competency']
                        ? options['competency']
                        : []
                    "
                    :disabled="true"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol md="6">
              <CRow>
                <label class="col-lg-12">Communications skills</label>
                <CCol md="12">
                  <RadioButton
                    name="communications_skills"
                    :value="feedBack.communications_skills"
                    :options="
                      options && options['competency']
                        ? options['competency']
                        : []
                    "
                    :disabled="true"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="6">
              <CRow>
                <label class="col-lg-12">Personality</label>
                <CCol md="12">
                  <RadioButton
                    name="personality"
                    :value="feedBack.personality"
                    :options="
                      options && options['competency']
                        ? options['competency']
                        : []
                    "
                    :disabled="true"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol md="6">
              <CRow>
                <label class="col-lg-12">Interpersonal skills</label>
                <CCol md="12">
                  <RadioButton
                    name="interpersonal_skills"
                    :value="feedBack.interpersonal_skills"
                    :options="
                      options && options['competency']
                        ? options['competency']
                        : []
                    "
                    :disabled="true"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="6">
              <CRow>
                <label class="col-lg-12">Appearance</label>
                <CCol md="12">
                  <RadioButton
                    name="appearance"
                    :value="feedBack.appearance"
                    :options="
                      options && options['competency']
                        ? options['competency']
                        : []
                    "
                    :disabled="true"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow class="mb-2">
            <CCol md="6">
              <CRow>
                <label class="col-lg-12">Overall Rating</label>
                <CCol md="12">
                  <Rating
                    name="overall_rating"
                    :value="feedBack.overall_rating"
                    :isNumberRating="true"
                    :disabled="true"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="6">
              <CRow>
                <label class="col-lg-12"
                  >All competencies weighted equally</label
                >
                <CCol md="12">
                  <TextInput
                    name="weighted_avg"
                    :value="feedBack.weighted_avg"
                    :disabled="true"
                    :CSS_Style="disabled_style"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard class="mt-2">
        <CCardBody>
          <h6 class="text-primary">Interviewer's Comments</h6>
          <p>Provide comments based on evaluation.</p>
          <CRow class="md-2">
            <CCol md="6">
              <CRow>
                <label class="col-lg-12 col-md-12">Strengths</label>
                <CCol md="12">
                  <TextareaInput
                    name="strengths"
                    :value="feedBack.strengths"
                    :disabled="true"
                    :CSS_Style="disabled_style"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="6">
              <CRow>
                <label class="col-lg-12 col-md-12">Weakness</label>
                <CCol md="12">
                  <TextareaInput
                    name="weakness"
                    :value="feedBack.weakness"
                    :disabled="true"
                    :CSS_Style="disabled_style"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
          <CRow class="md-2">
            <CCol md="6">
              <CRow>
                <label class="col-lg-12 col-md-12">Comments</label>
                <CCol md="12">
                  <TextareaInput
                    name="comments"
                    :value="feedBack.comments"
                    :disabled="true"
                    :CSS_Style="disabled_style"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard>
        <CCardBody>
          <h6 class="text-primary">Final Decision</h6>
          <CRow class="md-2">
            <CCol md="6">
              <CRow>
                <label class="col-lg-12">Recommended for hire</label>
                <CCol md="12">
                  <RadioButton
                    name="recommend_for_hire"
                    :value="feedBack.recommend_for_hire"
                    :options="
                      options && options['boolean'] ? options['boolean'] : []
                    "
                    :disabled="true"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="6">
              <CRow>
                <label class="col-lg-12">Hold/Keep for Future</label>
                <CCol md="12">
                  <RadioButton
                    name="hold_for_future"
                    :value="feedBack.hold_for_future"
                    :options="
                      options && options['booleanNull']
                        ? options['booleanNull']
                        : []
                    "
                    :disabled="true"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
      <CCard class="mt-2">
        <CCardBody>
          <h6 class="text-primary">Interviewer Details</h6>
          <CRow class="mb-2">
            <CCol md="6">
              <CRow>
                <label class="col-lg-12">Name</label>
                <CCol md="12">
                  <TextInput
                    name="interviewer_name"
                    :value="interview.interviewer_name"
                    :disabled="true"
                    :CSS_Style="disabled_style"
                  />
                </CCol>
              </CRow>
            </CCol>
            <CCol md="6">
              <CRow>
                <label class="col-lg-12">Date</label>
                <CCol md="12">
                  <TextInput
                    name="feedBackOn"
                    :value="interview.feedBackOn"
                    :disabled="true"
                    :CSS_Style="disabled_style"
                  />
                </CCol>
              </CRow>
            </CCol>
          </CRow>
        </CCardBody>
      </CCard>
    </div>
    <div class="print" ref="print" :class="showPrint ? 'd-block' : 'd-none'">
      <div>
        <div class="d-flex justify-content-between">
          <div></div>
          <h4 class="text-center text-danger">STRICTLY CONFIDENTIAL</h4>
          <div>
            <h5 class="m-2 text-center">Human Resources</h5>
            <h6 class="text-danger m-2 text-center">CONTROLLED DOCUMENT</h6>
          </div>
        </div>
        <div class="container">
          <h5 class="text-center">
            {{ candidateInfo.interviewer }} -Interview Evaluation Form
          </h5>

          <table width="100%" class="table">
            <colgroup>
              <col span="1" style="width: 25%" />
              <col span="1" style="width: 25%" />
              <col span="1" style="width: 25%" />
              <col span="1" style="width: 25%" />
            </colgroup>
            <tbody>
              <tr>
                <th class="label">Candidate Name:</th>
                <td>{{ candidateInfo.full_name }}</td>
                <th class="label">Candidate Type:</th>
                <td>{{ candidateInfo.candidate_type }}</td>
              </tr>
              <tr>
                <th class="label">Current Employer:</th>
                <td>{{ feedBack.current_employer }}</td>
                <th class="label">Notice Period:</th>
                <td>
                  <span v-if="candidateInfo.notice_period_days">
                    {{ candidateInfo.notice_period_days }}
                    {{ candidateInfo.notice_period_days ? `Days` : "" }}
                  </span>
                  <span v-else>N/A</span>
                </td>
              </tr>
              <tr>
                <th class="label">Current Salary:</th>
                <td>
                  <span v-if="candidateInfo.current_salary">
                    {{ candidateInfo.current_salary }}
                    {{ candidateInfo.current_salary_currency }}
                  </span>
                  <span v-else>N/A</span>
                </td>
                <th class="label">Excepted Salary:</th>
                <td>
                  <span v-if="candidateInfo.salary_expectation_min">
                    {{ candidateInfo.salary_expectation_min }} -
                    {{ candidateInfo.salary_expectation_max }}
                    {{ candidateInfo.salary_expectation_currency }}
                  </span>
                  <span v-else>N/A</span>
                </td>
              </tr>
              <tr>
                <th class="label">Age and Nationality:</th>
                <td>
                  {{ candidateInfo.age }} /
                  {{ candidateInfo.nationality_names | arrayToString }}
                </td>
                <th class="label">Marital Status and Children:</th>
                <td>
                  <span v-if="candidateInfo.marital_status">
                    {{ candidateInfo.marital_status }}
                    <span v-if="candidateInfo.marital_status === 'Yes'">
                      / {{ candidateInfo.dependents }}</span
                    >
                  </span>
                  <span>N/A</span>
                </td>
              </tr>
              <tr>
                <th class="label">Languages Written/Spoken:</th>
                <td colspan="6">
                  {{ candidateInfo.candidate_languages | arrayToString }}
                </td>
              </tr>
              <tr>
                <th class="label">Comments:</th>
                <td colspan="6" class="text-left">
                  {{ feedBack.candidate_tab_comments }}
                </td>
              </tr>
            </tbody>
          </table>

          <table width="100%" class="mt-4">
            <tr>
              <th class="label w-25">Interviewer's</th>
              <td>
                {{ actionDetailInfo.assigned_to_users_name | arrayToString }}
              </td>
            </tr>
            <tr>
              <th class="label w-25">Interviewee's Specialty</th>
              <td>
                {{ candidateInfo.candidate_specialities | arrayToString }}
              </td>
            </tr>
            <tr>
              <th class="label w-25">Date/Time</th>
              <td>{{ actionDetailInfo.datetime || "N/A" }}</td>
            </tr>
            <tr>
              <th class="label w-25">Venue</th>
              <td>{{ feedBack.venue || "N/A" }}</td>
            </tr>
            <tr>
              <th class="label w-25">Interview Method</th>
              <td>{{ actionDetailInfo.interview_mode | toSentance }}</td>
            </tr>
            <tr>
              <th class="label w-25">Candidate's Current Location</th>
              <td>{{ candidateInfo.current_country || "N/A" }}</td>
            </tr>
            <tr>
              <th class="label w-25">Licensure Status</th>
              <td>{{ candidateInfo.licenses || "N/A" }}</td>
            </tr>
            <tr>
              <th class="label w-25">Source</th>
              <td>{{ feedBack.source || "N/A" }}</td>
            </tr>
            <tr>
              <th class="label">Comments:</th>
              <td colspan="2" class="text-left">
                {{ feedBack.interview_tab_comments }}
              </td>
            </tr>
          </table>

          <table width="100%" class="mt-4">
            <thead>
              <tr>
                <th class="label w-40" rowspan="2">Competency</th>
                <th class="w-15 text-center">Exceeds Expectations</th>
                <th class="w-15 text-center">Meets Expectations</th>
                <th class="w-15 text-center">Satisfactory</th>
                <th class="w-15 text-center">Does not meet expectations</th>
              </tr>
              <tr>
                <th class="w-15 text-center">100%</th>
                <th class="w-15 text-center">75%</th>
                <th class="w-15 text-center">50%</th>
                <th class="w-15 text-center">0%</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th class="label">Relevant Work Experience</th>
                <td>
                  <div
                    class="checkmark"
                    :class="{ checked: feedBack.relevant_work_exp === 100 }"
                  >
                    <span></span>
                  </div>
                </td>
                <td>
                  <div
                    class="checkmark"
                    :class="{ checked: feedBack.relevant_work_exp === 75 }"
                  >
                    <span></span>
                  </div>
                </td>
                <td>
                  <div
                    class="checkmark"
                    :class="{ checked: feedBack.relevant_work_exp === 50 }"
                  >
                    <span></span>
                  </div>
                </td>
                <td>
                  <div
                    class="checkmark"
                    :class="{ checked: feedBack.relevant_work_exp === 0 }"
                  >
                    <span></span>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="label">Knowledge of specific job skills</th>
                <td>
                  <div
                    class="checkmark"
                    :class="{
                      checked: feedBack.knowledge_spec_job_skills === 100,
                    }"
                  >
                    <span></span>
                  </div>
                </td>
                <td>
                  <div
                    class="checkmark"
                    :class="{
                      checked: feedBack.knowledge_spec_job_skills === 75,
                    }"
                  >
                    <span></span>
                  </div>
                </td>
                <td>
                  <div
                    class="checkmark"
                    :class="{
                      checked: feedBack.knowledge_spec_job_skills === 50,
                    }"
                  >
                    <span></span>
                  </div>
                </td>
                <td>
                  <div
                    class="checkmark"
                    :class="{
                      checked: feedBack.knowledge_spec_job_skills === 0,
                    }"
                  >
                    <span></span>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="label">Communications skills</th>
                <td>
                  <div
                    class="checkmark"
                    :class="{
                      checked: feedBack.communications_skills === 100,
                    }"
                  >
                    <span></span>
                  </div>
                </td>
                <td>
                  <div
                    class="checkmark"
                    :class="{
                      checked: feedBack.communications_skills === 75,
                    }"
                  >
                    <span></span>
                  </div>
                </td>
                <td>
                  <div
                    class="checkmark"
                    :class="{
                      checked: feedBack.communications_skills === 50,
                    }"
                  >
                    <span></span>
                  </div>
                </td>
                <td>
                  <div
                    class="checkmark"
                    :class="{
                      checked: feedBack.communications_skills === 0,
                    }"
                  >
                    <span></span>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="label">Personality</th>
                <td>
                  <div
                    class="checkmark"
                    :class="{ checked: feedBack.personality === 100 }"
                  >
                    <span></span>
                  </div>
                </td>
                <td>
                  <div
                    class="checkmark"
                    :class="{ checked: feedBack.personality === 75 }"
                  >
                    <span></span>
                  </div>
                </td>
                <td>
                  <div
                    class="checkmark"
                    :class="{ checked: feedBack.personality === 50 }"
                  >
                    <span></span>
                  </div>
                </td>
                <td>
                  <div
                    class="checkmark"
                    :class="{ checked: feedBack.personality === 0 }"
                  >
                    <span></span>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="label">Interpersonal skills</th>
                <td>
                  <div
                    class="checkmark"
                    :class="{
                      checked: feedBack.interpersonal_skills === 100,
                    }"
                  >
                    <span></span>
                  </div>
                </td>
                <td>
                  <div
                    class="checkmark"
                    :class="{
                      checked: feedBack.interpersonal_skills === 75,
                    }"
                  >
                    <span></span>
                  </div>
                </td>
                <td>
                  <div
                    class="checkmark"
                    :class="{
                      checked: feedBack.interpersonal_skills === 50,
                    }"
                  >
                    <span></span>
                  </div>
                </td>
                <td>
                  <div
                    class="checkmark"
                    :class="{
                      checked: feedBack.interpersonal_skills === 0,
                    }"
                  >
                    <span></span>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="label">Appearance</th>
                <td>
                  <div
                    class="checkmark"
                    :class="{ checked: feedBack.appearance === 100 }"
                  >
                    <span></span>
                  </div>
                </td>
                <td>
                  <div
                    class="checkmark"
                    :class="{ checked: feedBack.appearance === 75 }"
                  >
                    <span></span>
                  </div>
                </td>
                <td>
                  <div
                    class="checkmark"
                    :class="{ checked: feedBack.appearance === 50 }"
                  >
                    <span></span>
                  </div>
                </td>
                <td>
                  <div
                    class="checkmark"
                    :class="{ checked: feedBack.appearance === 0 }"
                  >
                    <span></span>
                  </div>
                </td>
              </tr>
              <tr>
                <th class="label">Overall Rating</th>
                <td class="text-center">
                  {{ feedBack.overall_rating || "N/A" }}
                </td>
                <td colspan="3">
                  All competencies weighted equally:<span
                    class="mx-2 font-weight-bold"
                    >{{ feedBack.weighted_avg || "N/A" }}</span
                  >
                </td>
              </tr>
              <tr>
                <th class="label">Comments:</th>
                <td colspan="6" class="text-left">
                  {{ feedBack.competency_tab_comments }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="page">
        <div
          class="justify-content-between"
          :class="showPrint ? 'd-none-print' : 'd-flex'"
        >
          <div></div>
          <h4 class="text-center text-danger">STRICTLY CONFIDENTIAL</h4>
          <div>
            <h5 class="m-2 text-center">Human Resources</h5>
            <h6 class="text-danger m-2 text-center">CONTROLLED DOCUMENT</h6>
          </div>
        </div>
        <div class="container mt-4">
          <h4>Interviewer's Comments</h4>
          <p>Provide comments based on evaluation.</p>
          <table width="100%">
            <tr>
              <th class="label w-50">Strength's:</th>
              <th class="label w-50">Weakness:</th>
            </tr>
            <tr class="h-150">
              <td class="text-left">{{ feedBack.strengths }}</td>
              <td class="text-left">{{ feedBack.weakness }}</td>
            </tr>
            <tr>
              <th class="label" colspan="2">Comments:</th>
            </tr>
            <tr class="h-150">
              <td colspan="2" class="text-left">{{ feedBack.comments }}</td>
            </tr>
          </table>

          <table width="100%" class="mt-4">
            <tr>
              <th class="label" colspan="3">Final Decision</th>
              <th class="label" colspan="3">Interviewer Details</th>
            </tr>
            <tr>
              <td class="label" rowspan="2">Recommended for hire</td>
              <td rowspan="2">
                Yes
                <div
                  class="checkmark"
                  :class="{ checked: feedBack.recommend_for_hire }"
                >
                  <span></span>
                </div>
              </td>
              <td rowspan="2">
                No
                <div
                  class="checkmark"
                  :class="{ checked: feedBack.recommend_for_hire === false }"
                >
                  <span></span>
                </div>
              </td>
              <td class="label">Name:</td>
              <td>{{ interview.interviewer_name }}</td>
            </tr>
            <tr>
              <td class="label">Date:</td>
              <td>{{ interview.feedBackOn }}</td>
            </tr>
            <tr>
              <td class="text-left">Hold/Keep for future</td>
              <td>
                Yes
                <div
                  class="checkmark"
                  :class="{ checked: feedBack.hold_for_future }"
                >
                  <span></span>
                </div>
              </td>
              <td>
                No
                <div
                  class="checkmark"
                  :class="{ checked: feedBack.hold_for_future === false }"
                >
                  <span></span>
                </div>
              </td>
              <td class="label" colspan="2">Signature:</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TextInput from "@/components/reusable/Fields/TextInput";
import TextareaInput from "@/components/reusable/Fields/TextareaInput";
import Rating from "@/components/reusable/Fields/Rating";
import Select from "@/components/reusable/Fields/Select";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import m from "moment";
import _ from "lodash";
import { mapActions } from "vuex";
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    modalTitle: {
      type: String,
      default: "Interview Evaluation Form",
    },
    feedBackInfo: {
      type: Object,
      default: null,
    },
    showPrint: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Select,
    TextInput,
    TextareaInput,
    Rating,
    RadioButton,
  },
  data() {
    return {
      disabled_style: "background-color: #f8f8f8 !important;",
    };
  },
  computed: {
    options() {
      return {
        competency: [
          { code: 100, label: "100%", info: "Exceeds Expectations" },
          { code: 75, label: "75%", info: "Meets Expectations" },
          { code: 50, label: "50%", info: "Satisfactory" },
          { code: 0, label: "0%", info: "Does not meet expectations" },
        ],
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
        marital_status: [
          { id: "Married", label: "Married" },
          { id: "Single", label: "Single" },
        ],
        booleanNull: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
          { code: null, label: "N/A" },
        ],
      };
    },
    candidateInfo() {
      if (this.feedBackInfo && this.feedBackInfo.candidate) {
        const { candidate, created_by_user } = this.feedBackInfo;
        return {
          full_name: `${candidate?.first_name} ${candidate?.surname}`,
          candidate_languages: candidate.candidate_languages.map(
            ({ language }) => language?.language
          ),
          notice_period_days: candidate?.notice_period_days,
          current_salary: candidate?.current_salary,
          candidate_type: candidate?.candidate_type?.candidate_type,
          interviewer: created_by_user
            ? `${created_by_user?.first_name} ${created_by_user?.surname}`
            : `${this.externalUser?.first_name} ${this.externalUser?.surname}`,
          current_salary_currency:
            candidate?.current_salary_currency?.currency_code,
          salary_expectation_min: candidate?.salary_expectation_min,
          salary_expectation_max: candidate?.salary_expectation_max,
          salary_expectation_currency:
            candidate?.salary_expectation_currency?.currency_code,
          age: candidate?.age,
          nationality_names: candidate?.nationality_names,
          marital_status: candidate?.marital_status,
          dependents: candidate?.dependents,
          candidate_specialities: candidate?.candidate_specialities.map(
            (v) => v?.speciality?.speciality
          ),
          current_country: candidate?.current_country?.country_name,
          licenses: candidate?.licenses?.length > 0 ? "Yes" : "No",
          display_uid: candidate?.display_uid,
        };
      }
      return {};
    },
    actionDetailInfo() {
      const ids = this.feedBackInfo.interview_action_details.map(
        ({ action_detail_id }) => action_detail_id
      );
      const maxId = Math.max(...ids);
      const index = ids.indexOf(maxId);
      let actionDetail = this.feedBackInfo.interview_action_details[index];
      actionDetail = {
        ...actionDetail,
        datetime: this.interviewTimeStr(
          actionDetail?.selected_slot_date,
          actionDetail?.selected_slot_start,
          actionDetail?.selected_slot_end
        ),
      };
      return actionDetail;
    },
    feedBack() {
      return this.feedBackInfo;
    },
    externalUser() {
      if (
        this.feedBack?.created_by_external &&
        this.feedBack?.created_by_external_user
      ) {
        
        return this.feedBack?.created_by_external_user;
      }
      return null;
    },
    interview() {
      const {
        last_modified_by_user,
        last_modified_on,
        created_by_user,
        created_on,
        created_by_external,
        external_interviewers,
      } = this.feedBack;
      const feedBackOn = last_modified_on
        ? this.formatDate(last_modified_on, "DD-MMM-YYYY hh:mm A")
        : this.formatDate(created_on, "DD-MMM-YYYY hh:mm A");
      if (last_modified_by_user || created_by_user) {
        if (last_modified_by_user) {
          return {
            interviewer_name: `${last_modified_by_user.first_name} ${last_modified_by_user.surname}`,
            feedBackOn,
          };
        }
        return {
          interviewer_name: `${created_by_user.first_name} ${created_by_user.surname}`,
          feedBackOn,
        };
      }
      if (created_by_external && this.externalUser) {
        const { first_name, surname } = this.externalUser;
        return {
          interviewer_name: `${first_name} ${surname}`,
          feedBackOn,
        };
      }
    },
  },
  methods: {
    ...mapActions(["htmlToPDF"]),
    generateReport() {
      const contentHtml = this.$refs.print.innerHTML;
      const css_string =
        ' .text-center {text-align: center;}.d-flex {display: flex;}.justify-content-center {justify-content: center;}.text-danger {color: #dc3545 !important;}.justify-content-between {-webkit-box-pack: justify !important;-ms-flex-pack: justify !important;justify-content: space-between !important;}.m-1 {margin: 0.25rem !important;}.m-2 {margin: 0.5rem !important;}.m-3 {margin: 0.75rem !important;}.m-4 {margin: 1rem !important;}.mt-4 {margin-top: 1rem !important;}.mr-2 {margin-right: 0.5rem !important;}.pr-2 {padding-right: 0.5rem !important;}.logo {max-height: 61px;}body {font-family: Poppins, sans-serif !important;}bodydiv.container {font-size: 12px;}.label {text-align: left;}.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {width: 100%;padding-right: var(--bs-gutter-x, 0.75rem);padding-left: var(--bs-gutter-x, 0.75rem);margin-right: auto;margin-left: auto;max-width: 1140px;}table, td, th {border: 1px solid;padding: 0.5rem 0.5rem;}th {background-color: #f1f1f1;}table {width: 100%;border-collapse: collapse;}.w-25 {width: 25%;}.w-15 {width: 15%;}.w-40 {width: 40%;}.checkmark {height: 25px;width: 25px;position: relative;margin: auto;top: 0;left: 0;border: 1px solid #736E7A;border-radius: 4px;background-color: #FFFFFF;}.checkmark span:after {content: "";position: absolute;}.checkmark.checked span:after {left: 9px;top: 5px;width: 5px;height: 10px;border: 1px solid black;border-width: 0 3px 3px 0;-webkit-transform: rotate(45deg);-ms-transform: rotate(45deg);transform: rotate(45deg);}.h-150 {height: 150px;}.footer {position: fixed;bottom: 0;width: 100%;}.page {page-break-before: always;} .d-none-print{display: flex;}';
      const html_string = `<html><style type="text/css">${css_string}</style><body class="pr-2">${contentHtml}</body></html>`;
      this.htmlToPDF({
        html_string,
        file_name: `candidate_${this.candidateInfo.display_uid}_job_${this.feedBack.interview_action_details[0].job_display_uid}`,
      });
    },
    interviewTimeStr(date, startTime, endTime) {
      if (date && startTime && endTime) {
        const _date = m(date).format("DD-MMM-YYYY");
        const _start = this.formatTime(startTime);
        const _end = this.formatTime(endTime);
        return `${_date}  ${_start} - ${_end}`;
      }
      return null;
    },
    formatTime(date) {
      if (date) {
        const dateObj = new Date();
        const dateSplit = date.split(":");
        dateObj.setHours(dateSplit[0], dateSplit[1], dateSplit[2]);
        return m(dateObj).format("hh:mm A");
      }
      return null;
    },
    formatDate(date, format = "DD-MM-YYYY") {
      if (date) return m.utc(date).local().format(format);
      return null;
    },
  },
  filters: {
    arrayToString(data) {
      if (data && Array.isArray(data) && data.length) return data.join(", ");
      return "N/A";
    },
    toSentance(data) {
      if (data) return _.startCase(data);
      return "N/A";
    },
  },
};
</script>
<style lang="scss" scoped>
.print {
  .logo {
    max-height: 61px;
  }

  body div.container {
    font-size: 12px;
  }

  .label {
    text-align: left !important;
  }

  table,
  td,
  th {
    border: 1px solid;
    padding: 0.5rem 0.5rem;
  }

  th {
    background-color: #f1f1f1 !important;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  .w-25 {
    width: 25%;
  }

  .w-15 {
    width: 15%;
  }

  .w-40 {
    width: 40%;
  }

  .checkmark {
    height: 25px;
    width: 25px;
    position: relative;
    margin: auto;
  }

  .checkmark span:after {
    content: "";
    position: absolute;
  }

  .checkmark.checked span:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid black;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .h-150 {
    height: 150px !important;
  }

  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
  }
  .d-none-print {
    display: none;
  }
  //   .page {
  //     page-break-before: always;
  //   }
}
</style>
