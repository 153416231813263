<template>
  <div class="contact_user_modal">
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Contact Users List</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>

      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          type="button"
          color="primary"
          class="px-4"
          :disabled="!contactExternalUsers.length"
          @click="onSubmit"
          >Submit</CButton
        >
      </template>
      <LoadingOverlay v-if="isFetching" />
      <CTabs>
        <CTab :title="`Contacts(${contacts.length})`">
          <div class="table-responsive">
            <table class="w-100 table" v-if="contacts.length">
              <colgroup>
                <col span="1" style="width: 20%" />
                <col span="1" style="width: 40%" />
                <col span="1" style="width: 40%" />
              </colgroup>
              <thead>
                <th colspan="1"></th>
                <th colspan="1">Name</th>
                <th colspan="1">Email</th>
              </thead>
              <tbody>
                <tr v-for="(contact, index) in contacts" :key="index">
                  <td>
                    <input
                      type="checkbox"
                      :value="contact"
                      :id="`contact_${index}_${contact.contact_id}`"
                      :checked="contact.selected || isSelected(contact)"
                      @change="handleChangeCheck($event, contact)"
                    />
                  </td>
                  <td>{{ contact.first_name }} {{ contact.surname }}</td>
                  <td>{{ contact.email }}</td>
                </tr>
              </tbody>
            </table>
            <p v-else class="text-muted text-center mt-2 font-weight-bold">
              No Data Found
            </p>
          </div>
        </CTab>
        <CTab :title="`External Users(${externalUsers.length})`">
          <div class="table-responsive">
            <table class="w-100 table" v-if="externalUsers.length">
              <colgroup>
                <col span="1" style="width: 20%" />
                <col span="1" style="width: 40%" />
                <col span="1" style="width: 40%" />
              </colgroup>
              <thead>
                <th colspan="1"></th>
                <th colspan="1">Name</th>
                <th colspan="1">Email</th>
              </thead>
              <tbody>
                <tr v-for="(contact, index) in externalUsers" :key="index">
                  <td>
                    <input
                      type="checkbox"
                      :value="contact"
                      :id="`external_${index}_${contact.external_user_id}`"
                      :checked="contact.selected || isSelected(contact)"
                      @change="handleChangeCheck($event, contact)"
                    />
                  </td>
                  <td>{{ contact.first_name }} {{ contact.surname }}</td>
                  <td>{{ contact.email }}</td>
                </tr>
              </tbody>
            </table>
            <p v-else class="text-muted text-center mt-2 font-weight-bold">
              No Data Found
            </p>
          </div>
        </CTab>
      </CTabs>
    </CModal>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: () => false,
    },
    jobDetail: {
      type: Object,
      default: () => {},
    },
    selectedContacts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      isFetching: false,
      payload: [],
    };
  },
  computed: {
    ...mapGetters(["contactExternalUsers"]),
    contacts() {
      return (
        this.contactExternalUsers
          .filter((v) => v?.contact_id && !v?.external_user_id)
          .map((v) => ({
            ...v,
            ...this.getFields(v),
            selected: this.checkSelectedContact(v),
          })) || []
      );
    },
    externalUsers() {
      return (
        this.contactExternalUsers
          .filter((v) => v?.external_user_id)
          .map((v) => ({
            ...v,
            ...this.getFields(v),
            selected: this.checkSelectedContact(v),
          })) || []
      );
    },
  },
  mounted() {
    const { organisation_id: org_id, organisation_uid } = this.jobDetail;
    const organisation_id = org_id || organisation_uid;
    this.isFetching = true;
    this.getContactExternalUsers({ organisation_id }).then((res) => {
      this.isFetching = false;
    });
    const selectedContacts = this.selectedContacts.filter(
      (v) => v?.contact_id || v?.external_user_id
    );
    this.payload = [...selectedContacts];
  },
  methods: {
    ...mapActions(["getContactExternalUsers"]),
    handleChangeCheck(e, value) {
      const { checked } = e.target;
      if (checked && value) {
        this.payload = [...this.payload, value];
      } else if (value) {
        _.remove(this.payload, { contact_id: value?.contact_id });
      }
    },
    isSelected(data) {
      return (
        this.payload?.some(
          ({ contact_id, external_user_id }) =>
            (contact_id && contact_id === data.contact_id) ||
            (external_user_id && external_user_id === data.external_user_id)
        ) || false
      );
    },
    getFields(data) {
      if (data?.contact_id && data?.contact) {
        const { name, email, contact_id } = data?.contact;
        const { first_name, surname } = this.extractName(name);
        return {
          first_name,
          surname,
          email,
          name,
          contact_id,
        };
      }
      return null;
    },
    extractName(name) {
      if (name) {
        const split_index = name.trim().lastIndexOf(" ");
        const first_name = name.substr(0, split_index);
        const surname = name.substr(split_index + 1);
        return { first_name, surname };
      }
      return {};
    },
    checkSelectedContact(data) {
      if (data)
        return (
          (this.selectedContacts &&
            this.selectedContacts.some(
              (v) =>
                (v?.contact_id && v?.contact_id === data?.contact_id) ||
                (v?.external_user_id &&
                  v?.external_user_id === data?.external_user_id)
            )) ||
          false
        );
      return false;
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
    onSubmit() {
      const payload = this.payload.map((v) => ({
        first_name: v?.first_name,
        surname: v?.surname,
        email: v?.email,
        contact_id: v?.contact_id,
        external_user_id: v?.external_user_id,
      }));
      this.modalCallBack(true, payload);
    },
  },
};
</script>