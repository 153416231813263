<template>
  <div>
    <!--Whenever vue-editor is used Advert-section class name should be given which makes <strong> tag to bolder(visible) -->
    <vue-editor
      :id="name"
      class="advert-section"
      :name="name"
      :value="value"
      @input="input"
      :disabled="disabled"
      :editorToolbar="customToolbar"
      :placeholder="placeholder"
    />
  </div>
</template>
<script>
export default {
  name: "RichTextEditor",
  props: {
    name: {
      type: String,
      default: "EditorBox",
    },
    placeholder: {
      type: String,
      default: "Start typing...",
    },
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    customToolbar: {
      type: Array,
      default: () => [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ["bold", "italic", "underline", "strike"], // toggled buttons
        [{ header: 1 }, { header: 2 }],
        [{ align: [] }],
        ["blockquote", "code-block"],
        [{ list: "ordered" }, { list: "bullet" }],
        // [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
        [{ script: "sub" }, { script: "super" }],
        [{ direction: "rtl" }],
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        ["link", "image"],
        ["clean"], // remove formatting button
      ],
      // Available buttons for the toolbar
      // ["bold", "italic", "underline", "strike"],
      // ["blockquote", "code-block"],
      // [{ header: 1 }, { header: 2 }],
      // [{ list: "ordered" }, { list: "bullet" }],
      // [{ script: "sub" }, { script: "super" }],
      // [{ indent: "-1" }, { indent: "+1" }],
      // [{ direction: "rtl" }],
      // [{ size: ["small", false, "large", "huge"] }],
      // [{ header: [1, 2, 3, 4, 5, 6, false] }],
      // [{ font: [] }],
      // [{ color: [] }, { background: [] }],
      // [{ align: [] }],
      // [
      //   { align: "" },
      //   { align: "center" },
      //   { align: "right" },
      //   { align: "justify" },
      // ],
      // ["clean"],
      // ["link", "image", "video"],
    },
  },
  methods: {
    input(data) {
      // Replace used to remove unwanted code between two tags and Replace All used to remove the margin bottom of <p> tag
      data = data
        .replace(/\>\s+\</g, "><")
        .replaceAll("<p>", '<p class="mb-0">');
      // Replace All used to make strong tag more visible for <p> tag
      data = data.replaceAll("<strong>", '<strong style="font-weight:bold">');
      // Make empty string  if data is null
      if (data == '<p class="mb-0"><br></p>' || data == '<p class="mb-0"></p>')
        data = "";
      this.$emit("change", this.name, data);
      this.$emit("input", this.name, data);
    },
  },
};
</script>
<style lang="scss">
.advert-section {
  b,
  strong {
    font-weight: bold;
  }
}
</style>
