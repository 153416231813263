<template>
  <div class="exter-interviewer">
    <CRow
      v-for="(external_interviewer, index) in external_interviewers"
      :key="`interviewer_${index}`"
    >
      <CCol md="3">
        <CRow class="mb-3">
          <label class="col-lg-12">First Name</label>
          <div class="col-lg-12">
            <TextInput
              :name="`first_name__${index}`"
              :value="external_interviewer.first_name"
              @input="handleInputCustom"
              :disabled="
                !!(
                  external_interviewer.contact_id ||
                  external_interviewer.external_user_id
                )
              "
            />
          </div>
        </CRow>
      </CCol>
      <CCol md="3">
        <CRow class="mb-3">
          <label class="col-lg-12">Last Name</label>
          <div class="col-lg-12">
            <TextInput
              :name="`surname__${index}`"
              :value="external_interviewer.surname"
              @input="handleInputCustom"
              :disabled="
                !!(
                  external_interviewer.contact_id ||
                  external_interviewer.external_user_id
                )
              "
            />
          </div>
        </CRow>
      </CCol>
      <CCol md="6">
        <CRow class="mb-3">
          <label class="col-lg-12">Email</label>
          <div class="col-lg-12">
            <div class="d-flex align-items-center">
              <div class="w-100">
                <ValidationProvider rules="email" v-slot="{ errors }">
                  <TextInput
                    :name="`email__${index}`"
                    :value="external_interviewer.email"
                    @input="handleInputCustom"
                    :error="errors[0]"
                    :disabled="
                      !!(
                        external_interviewer.contact_id ||
                        external_interviewer.external_user_id
                      )
                    "
                  />
                </ValidationProvider>
              </div>
              <div class="w-25">
                <span
                  class="mx-1 cursor-pointer"
                  v-c-tooltip="`Select from Contact User`"
                  @click="openContactUsers"
                >
                  <i class="fa-solid fa-users"></i>
                </span>
                <span
                  v-if="index === external_interviewers.length - 1"
                  class="p-1 btn text-success"
                  v-c-tooltip="`Create Additional Interviewer`"
                  @click="addNewAddInterviewer"
                >
                  <i class="fa-solid fa-circle-plus fs-20"></i>
                </span>
                <span
                  v-if="external_interviewers.length > 1"
                  class="p-1 btn text-danger"
                  v-c-tooltip="`Create Additional Interviewer`"
                  @click="removeInterviewer(index)"
                >
                  <i class="fa-sharp fa-solid fa-circle-minus"></i>
                </span>
              </div>
            </div>
          </div>
        </CRow>
      </CCol>
    </CRow>
    <ContactUserModal
      v-if="contactUserModal.isShowPopup"
      :isShowPopup="contactUserModal.isShowPopup"
      :jobDetail="jobDetail"
      :selectedContacts="external_interviewers"
      @modalCallBack="contactUserModalCallBack"
    />
  </div>
</template>
<script>
import Vue from "vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import ContactUserModal from "@/components/reusable/GenericTabFilters/contactUsersModal";
import { required, email } from "vee-validate/dist/rules";
import { extend } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
extend("email", { ...email, message: "Invalid email" });
extend("required", { ...required, message: "This field is required" });
export default {
  props: {
    jobDetail: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      external_interviewers: [],
      contactUserModal: {
        isShowPopup: false,
      },
    };
  },
  computed: {
    ...mapGetters(["getCustomerId"]),
  },
  components: {
    TextInput,
    ContactUserModal,
  },
  methods: {
    ...mapActions(["addExternalUsers"]),
    async externalUser() {
      const hasAdditionalInterviewers = this.external_interviewers.filter(
        (v) => v?.email || v?.contact_id || v?.external_user_id
      );
      if (hasAdditionalInterviewers.length) {
        let external_interviewer_ids =
          this.external_interviewers
            .filter((v) => v?.external_user_id)
            .map((v) => v?.external_user_id) || [];
        const interviewerPayload =
          this.external_interviewers
            .filter((v) => !v?.external_user_id)
            .map((v) => ({
              first_name: v?.first_name,
              surname: v?.surname,
              email: v?.email,
              customer_uid: this.getCustomerId,
              contact_id: v?.contact_id || null,
              organisation_id:
                this.jobDetail?.organisation_id ||
                this.jobDetail?.organisation_uid,
            })) || [];
        if (interviewerPayload.length) {
          return await this.addExternalUsers(interviewerPayload).then((res) => {
            if (res.status === 200) {
              const { data } = res;
              const newExternalUsers =
                data.map((v) => v?.external_user_id) || [];
              external_interviewer_ids = [
                ...external_interviewer_ids,
                ...newExternalUsers,
              ];
              return external_interviewer_ids;
            } else {
              return null;
            }
          });
        }
        return external_interviewer_ids;
      }
      return [];
    },
    handleInputCustom(_name, value) {
      const { name, index } = this.decodeName(_name);
      Vue.set(this.external_interviewers[index], name, value);
    },
    decodeName(data) {
      if (data) {
        const split_index = data.lastIndexOf("__");
        const name = data.substr(0, split_index);
        const index = data.substr(split_index + 2) || null;
        return { name, index };
      }
      return data;
    },
    openContactUsers() {
      this.contactUserModal.isShowPopup = true;
    },
    contactUserModalCallBack(action, data) {
      if (action && data) {
        const newContacts =
          data.filter(
            (v) =>
              !this.external_interviewers.some(
                (e) =>
                  (e?.contact_id && e?.contact_id === v?.contact_id) ||
                  (e?.external_user_id &&
                    e?.external_user_id === v?.external_user_id)
              )
          ) || [];
        const exInterviewers = this.external_interviewers.filter((v) => {
          if (v?.contact_id || v?.external_user_id) {
            return data.some(
              (e) =>
                (e?.contact_id && e?.contact_id === v?.contact_id) ||
                (e?.external_user_id &&
                  e.external_user_id === v?.external_user_id)
            );
          }
          return v?.email || v?.first_name || v?.surname;
        });
        this.external_interviewers = [...exInterviewers, ...newContacts];
        if (!this.external_interviewers.length) {
          this.addNewAddInterviewer();
        }
      }
      this.contactUserModal.isShowPopup = false;
    },
    addNewAddInterviewer() {
      const interviewer = { first_name: null, surname: null, email: null };
      this.external_interviewers = [...this.external_interviewers, interviewer];
    },
    removeInterviewer(index) {
      if (this.external_interviewers[index]) {
        this.external_interviewers.splice(index, 1);
      }
      return;
    },
  },
  mounted() {
    this.addNewAddInterviewer();
  },
};
</script>