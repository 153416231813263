<template>
  <div class="onError">
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Actions</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton
          v-if="possibleStatus.length"
          type="button"
          color="primary"
          class="px-4"
          @click="onSubmit()"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="isFetchingFilter" />
      <div v-else>
        <div v-if="!possibleStatus.length" class="p-2">
          <h2 class="text-center">No Options Found</h2>
        </div>
        <ValidationObserver ref="errorStatus" v-slot="{ handleSubmit }" v-else>
          <form @submit.prevent="handleSubmit(onSubmit)" name="errorStatus">
            <CRow class="mb-3">
              <CCol md="6">
                <CRow>
                  <label class="required col-lg-12">Status</label>
                  <CCol lg="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="status_id"
                        @input="handleChangeSelect"
                        :value="errorData.status_id"
                        :options="(options && options['status_id']) || []"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow>
                  <label class="required col-lg-12">Sub Status</label>
                  <CCol lg="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="sub_status_id"
                        @input="handleChangeSelect"
                        :value="errorData.sub_status_id"
                        :options="[]"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :disabled="true"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
            <CRow class="mb-3">
              <CCol md="6">
                <CRow>
                  <label class="required col-lg-12">Comment</label>
                  <CCol lg="12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <TextAreaInput
                        name="comments"
                        :value="errorData.comments"
                        label="comments"
                        @input="handleInput"
                        :error="errors[0]"
                      ></TextAreaInput>
                    </ValidationProvider>
                  </CCol>
                </CRow>
              </CCol>
            </CRow>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import Select from "@/components/reusable/Fields/Select.vue";
import TextAreaInput from "@/components/reusable/Fields/TextareaInput";
import { mapActions, mapGetters } from "vuex";
import { extend } from "vee-validate";
import { required } from "vee-validate/dist/rules";
import _ from "lodash";
extend("required", { ...required, message: "This field is required" });
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    candidate: {
      type: Object,
      default: null,
    },
    job: {
      type: Object,
      default: null,
    },
    action: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      errorData: {},
      payload: {},
    };
  },
  components: {
    Select,
    TextAreaInput,
  },
  computed: {
    ...mapGetters(["isFetchingFilter", "jcpNegativeFlows"]),
    options() {
      return {
        status_id: this.possibleStatus || [],
      };
    },
    possibleStatus() {
      const actions =
        this.jcpNegativeFlows.map((v) => ({
          code: v?.status_id,
          label: v?.status,
          status_sub_status: `${v?.status_id} - ${v?.sub_status_id}`,
          sub_status: {
            code: v?.sub_status_id,
            label: v?.sub_status,
          },
        })) || [];
      return _.uniqBy(actions, function (e) {
        return e.status_sub_status;
      });
    },
  },
  methods: {
    ...mapActions(["fetchJCPNegativeFlows", "showToast"]),
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
    handleInput(name, value) {
      Vue.set(this.errorData, name, value);
      Vue.set(this.payload, name, value);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.errorData, name, value);
      this.payload = {
        ...this.payload,
        [name]: value?.code || null,
      };
      if (name === "status_id") {
        // const sub_status = value?.sub_status?.sub_status_id ? value?.sub_status: null
        this.handleChangeSelect("sub_status_id", value?.sub_status);
      }
    },
    async onSubmit() {
      const isValid = await this.$refs.errorStatus.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      this.modalCallBack(true, this.payload);
    },
  },
  mounted() {
    const { job_id } = this.job;
    const { candidate_uid } = this.candidate;
    this.fetchJCPNegativeFlows({ job_id, candidate_uid });
  },
};
</script>
