var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"exter-interviewer"},[_vm._l((_vm.external_interviewers),function(external_interviewer,index){return _c('CRow',{key:`interviewer_${index}`},[_c('CCol',{attrs:{"md":"3"}},[_c('CRow',{staticClass:"mb-3"},[_c('label',{staticClass:"col-lg-12"},[_vm._v("First Name")]),_c('div',{staticClass:"col-lg-12"},[_c('TextInput',{attrs:{"name":`first_name__${index}`,"value":external_interviewer.first_name,"disabled":!!(
                external_interviewer.contact_id ||
                external_interviewer.external_user_id
              )},on:{"input":_vm.handleInputCustom}})],1)])],1),_c('CCol',{attrs:{"md":"3"}},[_c('CRow',{staticClass:"mb-3"},[_c('label',{staticClass:"col-lg-12"},[_vm._v("Last Name")]),_c('div',{staticClass:"col-lg-12"},[_c('TextInput',{attrs:{"name":`surname__${index}`,"value":external_interviewer.surname,"disabled":!!(
                external_interviewer.contact_id ||
                external_interviewer.external_user_id
              )},on:{"input":_vm.handleInputCustom}})],1)])],1),_c('CCol',{attrs:{"md":"6"}},[_c('CRow',{staticClass:"mb-3"},[_c('label',{staticClass:"col-lg-12"},[_vm._v("Email")]),_c('div',{staticClass:"col-lg-12"},[_c('div',{staticClass:"d-flex align-items-center"},[_c('div',{staticClass:"w-100"},[_c('ValidationProvider',{attrs:{"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function({ errors }){return [_c('TextInput',{attrs:{"name":`email__${index}`,"value":external_interviewer.email,"error":errors[0],"disabled":!!(
                      external_interviewer.contact_id ||
                      external_interviewer.external_user_id
                    )},on:{"input":_vm.handleInputCustom}})]}}],null,true)})],1),_c('div',{staticClass:"w-25"},[_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:(`Select from Contact User`),expression:"`Select from Contact User`"}],staticClass:"mx-1 cursor-pointer",on:{"click":_vm.openContactUsers}},[_c('i',{staticClass:"fa-solid fa-users"})]),(index === _vm.external_interviewers.length - 1)?_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:(`Create Additional Interviewer`),expression:"`Create Additional Interviewer`"}],staticClass:"p-1 btn text-success",on:{"click":_vm.addNewAddInterviewer}},[_c('i',{staticClass:"fa-solid fa-circle-plus fs-20"})]):_vm._e(),(_vm.external_interviewers.length > 1)?_c('span',{directives:[{name:"c-tooltip",rawName:"v-c-tooltip",value:(`Create Additional Interviewer`),expression:"`Create Additional Interviewer`"}],staticClass:"p-1 btn text-danger",on:{"click":function($event){return _vm.removeInterviewer(index)}}},[_c('i',{staticClass:"fa-sharp fa-solid fa-circle-minus"})]):_vm._e()])])])])],1)],1)}),(_vm.contactUserModal.isShowPopup)?_c('ContactUserModal',{attrs:{"isShowPopup":_vm.contactUserModal.isShowPopup,"jobDetail":_vm.jobDetail,"selectedContacts":_vm.external_interviewers},on:{"modalCallBack":_vm.contactUserModalCallBack}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }