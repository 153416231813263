<template>
  <div class="search col-lg-8 d-flex">
    <TextInput
      class="w-100"
      name="searchTerm"
      :placeholder="placeholder"
      @onEnter="handleSearchSubmit"
      @input="handleSearchInput"
      :value="searchTermValue"
    />
    <button
      class="btn btn-primary ml-2 px-3"
      @click="handleSearchSubmit"
      name="searchButton"
    >
      <i class="fa fa-search"></i>
    </button>

    <button
      class="btn btn-primary ml-2"
      v-if="searchTermValue"
      name="searchClose"
      @click="handleClear"
      buttonClasses="btn-primary ml-2 c-close"
    >
      <i class="fa fa-times"></i>
    </button>
    <!-- <CButtonClose
      v-if="searchTermValue"
      name="searchClose"
      @click="handleClear"
      buttonClasses="btn-primary ml-2 px-3 c-close"
    /> -->
  </div>
</template>

<script>
import TextInput from "@/components/reusable/Fields/TextInput";

export default {
  props: ["searchCallback", "value", "placeholder"],
  components: {
    TextInput,
  },
  data() {
    return {
      searchTermValue: "",
    };
  },
  watch: {
    value(val) {
      if (val) {
        if (typeof val === "string") {
          this.searchTermValue = val;
        } else if (Array.isArray(val)) {
          this.searchTermValue = val[0];
        }
      }else{
        this.searchTermValue = ""
      }
    },
  },
  methods: {
    handleSearchInput(name, value) {
      this.searchTermValue = value;
    },
    handleClear() {
      this.searchTermValue = "";
      this.searchCallback({
        type: "searchTerm",
        name: "searchTerm",
        value: "",
      });
    },
    handleSearchSubmit() {
      this.searchCallback({ name: "searchTerm", value: this.searchTermValue });
    },
  },
};
</script>

<style lang="scss" scoped></style>
