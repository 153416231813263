<template>
  <div class="fast-track-modal">
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">{{ modalTitle }}</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit()"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="isFetchingFilter" />
      <div v-else>
        <div class="text-primary mb-2 font-weight-bold">
          Status:&nbsp;&nbsp;&nbsp;&nbsp;{{ status }}
        </div>
        <ValidationObserver ref="candidateStatus" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)" name="candidateStatus">
            <CRow>
              <CCol md="6">
                <CRow class="mb-3">
                  <label class="required col-lg-12">Action</label>
                  <div class="col-lg-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="action_id"
                        @input="handleChangeSelect"
                        :value="actionData.action_id"
                        :options="[]"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
              <CCol md="6">
                <CRow class="mb-3">
                  <label class="required col-lg-12">Sub Status</label>
                  <div class="col-lg-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="sub_status_id"
                        @input="handleChangeSelect"
                        :value="actionData.sub_status_id"
                        :options="[]"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <div class="border-top-primary pt-3">
              <CRow>
                <CCol md="6">
                  <h6 class="text-primary">Type</h6>
                  <CRow>
                    <CCol col="4">
                      <label v-c-tooltip="'Screening'">
                        <input
                          name="type_of_process"
                          type="radio"
                          @input="
                            handleRadioCustom('type_of_process', 'screening')
                          "
                          :checked="actionData.type_of_process === 'screening'"
                        />
                        Screening
                      </label>
                    </CCol>
                    <CCol col="4">
                      <label v-c-tooltip="'Main'">
                        <input
                          name="type_of_process"
                          type="radio"
                          @input="handleRadioCustom('type_of_process', 'main')"
                          :checked="actionData.type_of_process === 'main'"
                        />
                        Main
                      </label>
                    </CCol>
                    <CCol col="4">
                      <label v-c-tooltip="'Followup'">
                        <input
                          name="type_of_process"
                          type="radio"
                          @input="
                            handleRadioCustom('type_of_process', 'follow_up')
                          "
                          :checked="actionData.type_of_process === 'follow_up'"
                        />
                        Followup
                      </label>
                    </CCol>
                  </CRow>
                  <h6 class="text-primary pt-2">Style</h6>
                  <CRow>
                    <CCol col="4">
                      <label v-c-tooltip="'Phone'">
                        <input
                          name="interview_mode"
                          type="radio"
                          @input="handleRadioCustom('interview_mode', 'call')"
                          :checked="actionData.interview_mode === 'call'"
                        />
                        <i class="fas fa-phone-alt mx-2"></i>
                      </label>
                    </CCol>
                    <CCol col="4">
                      <label v-c-tooltip="'Live Meeting'">
                        <input
                          name="interview_mode"
                          type="radio"
                          @input="handleRadioCustom('interview_mode', 'video')"
                          :checked="actionData.interview_mode === 'video'"
                        />
                        <i class="fas fa-video mx-2 text-primary"></i>
                      </label>
                    </CCol>
                    <CCol col="4">
                      <label v-c-tooltip="'In-Person'">
                        <input
                          name="interview_mode"
                          type="radio"
                          @input="
                            handleRadioCustom('interview_mode', 'in_person')
                          "
                          :checked="actionData.interview_mode === 'in_person'"
                        />
                        <i class="fas fa-user-alt mx-2 text-success"></i>
                      </label>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol md="6">
                  <h6 class="text-primary">Time</h6>
                  <CRow>
                    <CCol col="4">
                      <label v-c-tooltip="'15 min'">
                        <input
                          name="alloted_time"
                          type="radio"
                          @input="handleRadioCustom('alloted_time', '_15_Mins')"
                          :checked="actionData.alloted_time === '_15_Mins'"
                          class="mr-2"
                        />
                        15min
                      </label>
                    </CCol>
                    <CCol col="4">
                      <label v-c-tooltip="'30 mins'">
                        <input
                          name="alloted_time"
                          type="radio"
                          @input="handleRadioCustom('alloted_time', '_30_Mins')"
                          :checked="actionData.alloted_time === '_30_Mins'"
                          class="mr-2"
                        />
                        30min
                      </label>
                    </CCol>
                    <CCol col="4">
                      <label v-c-tooltip="'60 min'">
                        <input
                          name="alloted_time"
                          type="radio"
                          @input="handleRadioCustom('alloted_time', '_60_Mins')"
                          :checked="actionData.alloted_time === '_60_Mins'"
                          class="mr-2"
                        />
                        60min
                      </label>
                    </CCol>
                    <CCol col="4">
                      <label v-c-tooltip="'90 min'">
                        <input
                          name="alloted_time"
                          type="radio"
                          @input="handleRadioCustom('alloted_time', '_90_Mins')"
                          :checked="actionData.alloted_time === '_90_Mins'"
                          class="mr-2"
                        />
                        90min
                      </label>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </div>
            <div class="border-top-primary pt-3" v-if="actionData.alloted_time">
              <h6 class="text-primary">Select Availablity - Click Date/Time</h6>
              <CRow class="mb-3">
                <CCol md="6">
                  <div class="pt-2 calendar-pad">
                    <div class="calendar-wrapper">
                      <vue-cal
                        class="vuecal--primary-theme"
                        xsmall
                        hide-view-selector
                        :time="false"
                        active-view="month"
                        :disable-views="['week', 'day']"
                        :dblclick-to-navigate="false"
                        style="height: 260px"
                        :events="events"
                        :min-date="new Date()"
                        :selected-date="actionData.selected_slot_date"
                      >
                        <template v-slot:cell-content="{ cell, events }">
                          <div @click="dateClick(cell, events)">
                            <span class="vuecal__cell-date">{{
                              cell.content
                            }}</span>
                            <span
                              class="vuecal__cell-events-count"
                              v-if="events.length"
                              >{{ events.length }}</span
                            >
                          </div>
                        </template>
                      </vue-cal>
                    </div>
                  </div>
                </CCol>
                <CCol md="6">
                  <div>
                    <h6 class="text-primary text-center">
                      {{ actionData.selected_slot_date | formatDate }}
                    </h6>
                    <TimeSlot
                      name="slot1"
                      startTime="05:00"
                      endTime="23:00"
                      :interval="interval"
                      :date="actionData.selected_slot_date"
                      :disabledTime="[]"
                      :duration="duration"
                      :slotTimes="slotTime"
                      @input="handleTimeSlot"
                    />
                  </div>
                </CCol>
              </CRow>
            </div>
            <div
              class="border-top-primary pt-3"
              v-if="
                actionData.interview_mode &&
                ['video', 'call'].includes(actionData.interview_mode)
              "
            >
              <h6 class="text-primary">Interview</h6>
              <CRow>
                <CCol lg="6" v-if="actionData.interview_mode === 'video'">
                  <CRow class="mb-3">
                    <label class="col-lg-12 required">Interview Link</label>
                    <div class="col-lg-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="interview_link"
                          :value="actionData.interview_link"
                          label="interview_link"
                          @input="handleInput"
                          :error="errors[0]"
                        ></TextInput>
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol lg="6" v-if="actionData.interview_mode === 'call'">
                  <CRow class="mb-3">
                    <label class="col-lg-12 required">Phone Number</label>
                    <div class="col-lg-12">
                      <ValidationProvider
                        ref="phone"
                        v-slot="{ errors }"
                        :rules="{
                          required: true,
                          phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                        }"
                      >
                        <PhoneInputwithCountry
                          name="contact_phone"
                          :value="actionData.contact_phone"
                          :options="
                            options && options['dialCode']
                              ? options['dialCode']
                              : []
                          "
                          @change="handleInput"
                          :error="errors[0]"
                          ref="phoneNo"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
            </div>
            <div class="border-top-primary pt-3 pb-2">
              <div
                class="d-flex align-items-center cursor-pointer"
                @click="showAddInterviewer = !showAddInterviewer"
              >
                <h6 class="text-primary mb-2">Add Additional Interviewers</h6>
                <em
                  class="fa-solid text-primary mx-1 mb-2"
                  :class="
                    showAddInterviewer ? 'fa-angle-down' : 'fa-angle-right'
                  "
                  v-c-tooltip="'Click to Show/Hide Additional Interviewers'"
                />
              </div>
              <CCollapse v-show="showAddInterviewer">
                <AddExtraInterviewer
                  ref="addExtraInterviewer"
                  :jobDetail="jobDetail"
                />
              </CCollapse>
            </div>
            <div class="border-top-primary pt-3">
              <h6 class="text-primary">Alert</h6>
              <CRow>
                <CCol md="6">
                  <CRow class="mb-3">
                    <label class="col-lg-12">Candidate Joining Date</label>
                    <div class="col-lg-12">
                      <DatePicker
                        name="joining_date"
                        :value="actionData.joining_date"
                        @input="handleDatePickerChange"
                        format="DD-MM-YYYY"
                        :showFromToday="true"
                      />
                    </div>
                  </CRow>
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <CRow class="mb-3">
                    <label class="col-lg-12 required">Alert Set For</label>
                    <div class="col-lg-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <RadioButton
                          name="alert_days"
                          :value="actionData.alert_days"
                          :options="
                            options && options['alert_days']
                              ? options['alert_days']
                              : []
                          "
                          @change="handleChangeRadio"
                        />
                        <DatePicker
                          v-if="
                            payload.alert_days &&
                            payload.alert_days === 'custom'
                          "
                          name="expiry_date"
                          :value="actionData.expiry_date"
                          @input="handleDatePickerChange"
                          :error="errors[0]"
                          format="DD-MM-YYYY"
                          :showFromToday="true"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol lg="6">
                  <CRow class="mb-3">
                    <label name="lbl_urgent" class="col-lg-12 col-md-12"
                      >Is Urgent?</label
                    >
                    <CCol md="12">
                      <RadioButton
                        name="is_urgent"
                        :value="actionData.is_urgent"
                        :options="
                          options && options['boolean']
                            ? options['boolean']
                            : []
                        "
                        @change="handleChangeRadio"
                      />
                    </CCol>
                  </CRow>
                </CCol>
                <!-- <CCol lg="6">
                  <CRow class="mb-3">
                    <label class="col-lg-12 required">Priority Level</label>
                    <div class="col-lg-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="priority_level"
                          @input="handleChangeSelect"
                          :value="actionData.priority_level"
                          :options="
                            options && options['priority_level']
                              ? options['priority_level']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol> -->
              </CRow>
              <CRow>
                <CCol lg="8">
                  <CRow class="mb-3">
                    <div class="col-lg-12 col-md-12 d-flex pb-2">
                      <div>
                        <label class="text-nowrap pr-3 required">Comment</label>
                      </div>
                      <div
                        class="w-100"
                        v-if="
                          options['commentsOptions'] &&
                          options['commentsOptions'].length
                        "
                      >
                        <Select
                          name="customComments"
                          :value="actionData.customComments"
                          @input="handleChangeSelectCustom"
                          :options="
                            options && options['commentsOptions']
                              ? options['commentsOptions']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                        />
                      </div>
                    </div>
                    <CCol lg="12">
                      <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <TextAreaInput
                          name="comments"
                          :value="actionData.comments"
                          label="comments"
                          @input="handleInput"
                          :error="errors[0]"
                        ></TextAreaInput>
                      </ValidationProvider>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import m from "moment";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import TextAreaInput from "@/components/reusable/Fields/TextareaInput";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import TimeSlot from "@/components/reusable/Fields/TimeSlot";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import { extend } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import { isEmptyObjectCheck, isObject } from "@/helpers/helper";
import PhoneInputwithCountry from "@/components/reusable/Fields/PhoneInputwithCountry";
import AddExtraInterviewer from "@/components/reusable/GenericTabFilters/AddExtraInterviewer";
import { required, regex, min, numeric } from "vee-validate/dist/rules";
import _ from "lodash";
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";

extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});
extend("required", { ...required, message: "This field is required" });
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    details: {
      type: Object,
      default: null,
    },
    action: {
      type: Object,
      default: null,
    },
    modalTitle: {
      type: String,
      default: "FastTrack",
    },
    candidateDetail: {
      type: Object,
      default: {},
    },
    jobDetail: {
      type: Object,
      default: {},
    },
  },
  components: {
    Select,
    TextInput,
    DatePicker,
    TextAreaInput,
    TimeSlot,
    PhoneInputwithCountry,
    VueCal,
    RadioButton,
    AddExtraInterviewer,
  },
  data() {
    return {
      actionData: {},
      payload: {},
      slotTime: {},
      status: null,
      events: [],
      showAddInterviewer: false,
    };
  },
  computed: {
    ...mapGetters(["isFetchingFilter", "actionComments", "getCustomerId", "dialingCodeWithCountryCode"]),
    interval() {
      const mins =
        parseInt(this.actionData?.alloted_time.replace(/\D/g, "")) || 30;
      return mins > 30 ? 30 : mins;
    },
    duration() {
      return parseInt(this.convert2Sentence(this.actionData?.alloted_time));
    },
    options() {
      return {
        priority_level: [
          { code: 1, label: "High" },
          { code: 2, label: "Medium" },
          { code: 3, label: "Low" },
        ],
        commentsOptions: this.actionComments || [],
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
        alert_days: [
          { code: 1, label: "1 Day" },
          { code: 3, label: "3 Days" },
          { code: 7, label: "7 Days" },
          { code: "custom", label: "Customize" },
        ],
        dialCode: this.dialingCodeWithCountryCode || [],
      };
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "fetchCommentsfor_recruiter",
      "fetchCandidateJoiningDate",
    ]),
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
    convert2Sentence(data) {
      return _.startCase(data);
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.actionData, name, value);
      Vue.set(
        this.payload,
        name,
        value ? m(value).format("YYYY-MM-DD 00:00:00") : null
      );
    },
    handleInput(name, value) {
      Vue.set(this.actionData, name, value);
      Vue.set(this.payload, name, value);
    },
    handleChangeSelect(name, value) {
      Vue.set(this.actionData, name, value);
      this.payload = {
        ...this.payload,
        [name]: value?.code || value?.id || value,
      };
    },
    handleChangeSelectCustom(name, value) {
      const comment = "comments";
      const v = value ? value.id || value.code || value.label || value : null;
      Vue.set(this.actionData, comment, `Fast Track - ${v}`);
      Vue.set(this.actionData, name, v);
      this.payload = {
        ...this.payload,
        [comment]: `Fast Track - ${v}`,
      };
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value?.id === false || value?.code === false) {
        val = false;
      } else {
        val = value?.id || value?.code || value;
      }
      Vue.set(this.actionData, name, value);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
      if (name === "alert_days" && val != "custom") {
        this.handleDatePickerChange("expiry_date", m().add(val, "d").toDate());
      }
    },
    handleRadioCustom(name, value) {
      Vue.set(this.actionData, name, value);
      Vue.set(this.payload, name, value);
      const interview_mode = {
        interview_mode: "call",
      };
      if (
        interview_mode[name] === value &&
        isObject(this.candidateDetail) &&
        !isEmptyObjectCheck(this.candidateDetail)
      ) {
        const candidate_phone = this.candidateDetail?.phone || null;
        this.handleInput("contact_phone", candidate_phone);
      } else if (name === "interview_mode") {
        this.handleInput("contact_phone", null);
        this.handleInput("interview_link", null);
      }
    },
    dateClick(cell, events) {
      const { formattedDate } = cell;
      if (m(formattedDate) >= m().startOf("day")) {
        Vue.set(this.actionData, "selected_slot_date", formattedDate);
        Vue.set(this.payload, "selected_slot_date", formattedDate);
      }
    },
    handleTimeSlot(name, value) {
      Vue.set(this.slotTime, name, value);
    },
    async onSubmit() {
      const isValid = await this.$refs.candidateStatus.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let hasError = { hasError: false, errorMsg: "" };
      const slotKeys = Object.keys(this.slotTime);
      if (!slotKeys.length) {
        hasError.hasError = true;
        hasError.errorMsg = "Interview slot required";
      }
      if (
        !(
          this.payload["type_of_process"] &&
          this.payload["interview_mode"] &&
          this.payload["alloted_time"]
        )
      ) {
        hasError.hasError = true;
        hasError.errorMsg = !this.payload["type_of_process"]
          ? "Please Select Type of Interview Process"
          : !this.payload["interview_mode"]
          ? "please Select Style of Interview"
          : "Please Select Interview Duration";
      }
      if (hasError.hasError) {
        this.showToast({
          class: "bg-danger text-white",
          message: hasError.errorMsg,
        });
      } else {
        const { action_id, filter_id, tab_id } = this.action;
        const { status_id: current_status, sub_status_id: current_sub_status } =
          this.details;
        this.payload = {
          ...this.payload,
          filter_id,
          tab_id,
          current_status,
          current_sub_status,
          selected_slot_start: this.formatDate(
            this.slotTime?.slot1?.start,
            "HH:mm:ss"
          ),
          selected_slot_end: this.formatDate(
            this.slotTime?.slot1?.end,
            "HH:mm:ss"
          ),
        };
        const external_interviewer_ids =
          await this.$refs?.addExtraInterviewer?.externalUser();
        if (external_interviewer_ids) {
          this.payload = { ...this.payload, external_interviewer_ids };
          this.modalCallBack(true, this.payload);
        }
      }
    },
    formatDate(date, pattern = "YYYY-MM-DD") {
      if (date) return m(date).format(pattern);
      return null;
    },
  },
  mounted() {
    this.handleChangeSelect("action_id", { code: 6, label: "Interview" });
    this.handleChangeSelect("sub_status_id", { code: 55, label: "Scheduled" });
    // this.handleChangeSelect("priority_level", { code: 2, label: "Medium" });
    this.handleInput("comments", "Fast Track");
    this.fetchCommentsfor_recruiter({ status_id: 54, sub_status_id: 55 });
    this.handleChangeRadio("is_urgent", false);
    let alertDate = m().add(7, "d").toDate();
    this.handleDatePickerChange("expiry_date", alertDate);
    this.handleChangeRadio("alert_days", 7);
    const candidate_uid = this.candidateDetail?.candidate_uid;
    const job_id = this.jobDetail?.job_id;
    this.fetchCandidateJoiningDate({
      order_by: "-action_detail_id",
      date_field: "initiated_date",
      completed_by__isnull: true,
      skip: 0,
      limit: 1,
      action_id: 18,
      job_id,
      candidate_uid,
    }).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.length) {
          const joining_date = res?.data[0]?.joining_date;
          if (joining_date) {
            const joining_date_obj = m(joining_date).toDate();
            this.handleDatePickerChange("joining_date", joining_date_obj);
          }
        }
      }
    });
  },
  filters: {
    formatDate(date) {
      if (date) return m(date).format("DD-MMM-YYYY");
      return date;
    },
  },
};
</script>