<template>
  <div class="action-modal">
    <CModal
      color="primary"
      :show.sync="isShowPopup"
      :close-on-backdrop="false"
      size="lg"
    >
      <template #header>
        <h6 class="modal-title">Actions</h6>
        <CButtonClose @click="modalCallBack(false)" class="text-black" />
      </template>
      <template #footer>
        <CButton
          type="button"
          variant="outline"
          color="primary"
          class="px-4"
          @click="modalCallBack(false)"
          >Close</CButton
        >
        <CButton type="button" color="primary" class="px-4" @click="onSubmit()"
          >Save</CButton
        >
      </template>
      <LoadingOverlay v-if="isFetchingFilter" />
      <div v-else>
        <div class="text-primary mb-2 font-weight-bold">
          Status:&nbsp;&nbsp;&nbsp;&nbsp;{{ status }}
        </div>
        <ValidationObserver ref="candidateStatus" v-slot="{ handleSubmit }">
          <form @submit.prevent="handleSubmit(onSubmit)" name="candidateStatus">
            <CRow>
              <CCol lg="6">
                <CRow class="mb-3">
                  <label class="required col-lg-12">Action</label>
                  <div class="col-lg-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="action_id"
                        @input="handleChangeSelect"
                        :value="profile.action_id"
                        :options="[]"
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                        :disabled="true"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>

              <CCol lg="6">
                <CRow class="mb-3" v-if="options['sub_status'].length">
                  <label class="required col-lg-12">{{
                    isShortlistAction ? "Category" : "Sub Status"
                  }}</label>
                  <div class="col-lg-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="sub_status_id"
                        @input="handleChangeSelect"
                        :value="profile.sub_status_id"
                        :options="
                          options && options['sub_status']
                            ? options['sub_status']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <CRow>
              <!-- <CCol lg="6" v-if="options['reason_code'].length">
                <CRow class="mb-3">
                  <label class="required col-lg-12">Reason Code</label>
                  <div class="col-lg-12">
                    <ValidationProvider rules="required" v-slot="{ errors }">
                      <Select
                        name="reason_id"
                        @input="handleChangeSelect"
                        :value="profile.reason_id"
                        :options="
                          options && options['reason_code']
                            ? options['reason_code']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                        :error="errors[0]"
                      />
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol> -->
              <CCol md="12" v-if="isBoardedAction">
                <CRow class="mb-2">
                  <CCol md="6">
                    <CRow class="mb-3">
                      <label class="col-lg-12 required"
                        >Candidate Joining Date</label
                      >
                      <div class="col-lg-12">
                        <ValidationProvider
                          rules="required"
                          v-slot="{ errors }"
                        >
                          <DatePicker
                            name="joining_date"
                            :value="profile.joining_date"
                            @input="handleDatePickerChange"
                            format="DD-MM-YYYY"
                            :showFromToday="true"
                            :error="errors[0]"
                          />
                        </ValidationProvider>
                      </div>
                    </CRow>
                  </CCol>
                </CRow>
              </CCol>
              <CCol lg="8" v-if="!isSubmissionAction">
                <CRow class="mb-3">
                  <div class="col-lg-12 col-md-12 d-flex pb-2">
                    <div>
                      <label
                        class="text-nowrap pr-3"
                        :class="{ required: isOtherReason }"
                        >Comment</label
                      >
                    </div>
                    <div class="w-100" v-if="options['commentsOptions'].length">
                      <Select
                        name="customComments"
                        :value="profile.customComments"
                        @input="handleChangeSelectCustom"
                        :options="
                          options && options['commentsOptions']
                            ? options['commentsOptions']
                            : []
                        "
                        :taggable="false"
                        :multiple="false"
                        :clearable="false"
                      />
                    </div>
                  </div>
                  <!-- <label class="col-lg-12" :class="{ required: isOtherReason }"
                    >Comments</label
                  > -->
                  <div class="col-lg-12">
                    <ValidationProvider
                      :rules="{ required: isOtherReason }"
                      v-slot="{ errors }"
                    >
                      <TextAreaInput
                        name="comments"
                        :value="profile.comments"
                        label="comments"
                        @input="handleInput"
                        :error="errors[0]"
                      ></TextAreaInput>
                    </ValidationProvider>
                  </div>
                </CRow>
              </CCol>
            </CRow>
            <div v-if="isInterviewRequest" class="border-top-primary pt-3">
              <CRow>
                <CCol md="6">
                  <h6 class="text-primary">Type</h6>
                  <CRow>
                    <CCol col="4">
                      <label v-c-tooltip="'Screening'">
                        <input
                          name="type_of_process"
                          type="radio"
                          @input="
                            handleRadioCustom('type_of_process', 'screening')
                          "
                        />
                        Screening
                      </label>
                    </CCol>
                    <CCol col="4">
                      <label v-c-tooltip="'Main'">
                        <input
                          name="type_of_process"
                          type="radio"
                          @input="handleRadioCustom('type_of_process', 'main')"
                        />
                        Main
                      </label>
                    </CCol>
                    <CCol col="4">
                      <label v-c-tooltip="'Followup'">
                        <input
                          name="type_of_process"
                          type="radio"
                          @input="
                            handleRadioCustom('type_of_process', 'follow_up')
                          "
                        />
                        Followup
                      </label>
                    </CCol>
                  </CRow>
                  <h6 class="text-primary pt-2">Style</h6>
                  <CRow>
                    <CCol col="4">
                      <label v-c-tooltip="'Phone'">
                        <input
                          name="interview_mode"
                          type="radio"
                          @input="handleRadioCustom('interview_mode', 'call')"
                        />
                        <i class="fas fa-phone-alt mx-2"></i>
                      </label>
                    </CCol>
                    <CCol col="4">
                      <label v-c-tooltip="'Live Meeting'">
                        <input
                          name="interview_mode"
                          type="radio"
                          @input="handleRadioCustom('interview_mode', 'video')"
                        />
                        <i class="fas fa-video mx-2 text-primary"></i>
                      </label>
                    </CCol>
                    <CCol col="4">
                      <label v-c-tooltip="'In-Person'">
                        <input
                          name="interview_mode"
                          type="radio"
                          @input="
                            handleRadioCustom('interview_mode', 'in_person')
                          "
                        />
                        <i class="fas fa-user-alt mx-2 text-success"></i>
                      </label>
                    </CCol>
                  </CRow>
                </CCol>
                <CCol md="6">
                  <h6 class="text-primary">Time</h6>
                  <CRow>
                    <CCol col="4">
                      <label v-c-tooltip="'15 min'">
                        <input
                          name="alloted_time"
                          type="radio"
                          @input="handleRadioCustom('alloted_time', '_15_Mins')"
                          class="mr-2"
                        />
                        15min
                      </label>
                    </CCol>
                    <CCol col="4">
                      <label v-c-tooltip="'30 mins'">
                        <input
                          name="alloted_time"
                          type="radio"
                          @input="handleRadioCustom('alloted_time', '_30_Mins')"
                          class="mr-2"
                        />
                        30min
                      </label>
                    </CCol>
                    <CCol col="4">
                      <label v-c-tooltip="'60 min'">
                        <input
                          name="alloted_time"
                          type="radio"
                          @input="handleRadioCustom('alloted_time', '_60_Mins')"
                          class="mr-2"
                        />
                        60min
                      </label>
                    </CCol>
                    <CCol col="4">
                      <label v-c-tooltip="'90 min'">
                        <input
                          name="alloted_time"
                          type="radio"
                          @input="handleRadioCustom('alloted_time', '_90_Mins')"
                          class="mr-2"
                        />
                        90min
                      </label>
                    </CCol>
                  </CRow>
                </CCol>
              </CRow>
            </div>
            <div v-if="isInterviewSchedule">
              <h6 class="text-primary border-top-primary pt-3">
                Select Schedule
              </h6>
              <table
                class="table table-section table-striped border-bottom mb-3"
                style="table-layout: fixed"
              >
                <thead>
                  <th></th>
                  <th>Date</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                </thead>
                <tbody>
                  <tr v-for="slot in slots" :key="slot.name">
                    <td class="form-radio">
                      <label>
                        <input
                          name="slot"
                          type="radio"
                          @input="handleRadio(slot)"
                        />
                        {{ slot.name }}
                      </label>
                    </td>
                    <td>{{ slot.date | formatDate }}</td>
                    <td>{{ slot.start_time | formatTime }}</td>
                    <td>{{ slot.end_time | formatTime }}</td>
                  </tr>
                  <tr v-if="slots.length === 0">
                    <td colspan="4" class="text-center">
                      <p class="h5 text-muted">No Interview Slot Available</p>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h6
                class="text-primary border-top-primary pt-3"
                v-if="
                  getInterviewSchedule.length &&
                  (getInterviewSchedule[0].interview_mode === 'video' ||
                    getInterviewSchedule[0].interview_mode === 'call')
                "
              >
                Interview
              </h6>
              <CRow>
                <CCol
                  lg="6"
                  v-if="
                    getInterviewSchedule.length &&
                    getInterviewSchedule[0].interview_mode === 'video'
                  "
                >
                  <CRow class="mb-3">
                    <label class="col-lg-12 required">Interview Link</label>
                    <div class="col-lg-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <TextInput
                          name="interview_link"
                          :value="profile.interview_link"
                          label="interview_link"
                          @input="handleInput"
                          :error="errors[0]"
                        ></TextInput>
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol
                  lg="6"
                  v-if="
                    getInterviewSchedule.length &&
                    getInterviewSchedule[0].interview_mode === 'call'
                  "
                >
                  <CRow class="mb-3">
                    <label class="col-lg-12 required">Phone Number</label>
                    <div class="col-lg-12">
                      <ValidationProvider
                        ref="phone"
                        v-slot="{ errors }"
                        :rules="{
                          required: true,
                          phone_rule: /^\+\d{1,3}\^\d{1,15}$/,
                        }"
                      >
                        <PhoneInputwithCountry
                          name="contact_phone"
                          :value="profile.contact_phone"
                          :options="
                            options && options['dialingCode']
                              ? options['dialingCode']
                              : []
                          "
                          @change="handleInput"
                          :error="errors[0]"
                          ref="phoneNo"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
            </div>
            <div
              v-if="isInterviewSchedule"
              class="border-top-primary pt-3 pb-2"
            >
              <div
                class="d-flex align-items-center cursor-pointer"
                @click="showAddInterviewer = !showAddInterviewer"
              >
                <h6 class="text-primary mb-2">Add Additional Interviewers</h6>
                <em
                  class="fa-solid text-primary mx-1 mb-2"
                  :class="
                    showAddInterviewer ? 'fa-angle-down' : 'fa-angle-right'
                  "
                  v-c-tooltip="'Click to Show/Hide Additional Interviewers'"
                />
              </div>
              <CCollapse v-show="showAddInterviewer">
                <AddExtraInterviewer
                  ref="addExtraInterviewer"
                  :jobDetail="job"
                />
              </CCollapse>
            </div>
            <div v-if="isSubmissionAction">
              <h6 class="text-primary border-top-primary pt-3">Alert</h6>
              <CRow>
                <CCol md="6">
                  <CRow class="mb-3">
                    <label class="col-lg-12">Candidate Joining Date</label>
                    <div class="col-lg-12">
                      <DatePicker
                        name="joining_date"
                        :value="profile.joining_date"
                        @input="handleDatePickerChange"
                        format="DD-MM-YYYY"
                        :showFromToday="true"
                      />
                    </div>
                  </CRow>
                </CCol>
                <CCol md="6" v-if="isSubmissionOnlyAction">
                  <CButton
                    color="primary"
                    @click="openFrontSheet"
                    class="mb-3 mt-4"
                    >Frontsheet</CButton
                  >
                </CCol>
              </CRow>
              <CRow>
                <CCol lg="6">
                  <CRow class="mb-3">
                    <label class="col-lg-12 required">Alert Set For</label>
                    <div class="col-lg-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <RadioButton
                          name="alert_days"
                          :value="profile.alert_days"
                          :options="
                            options && options['alert_days']
                              ? options['alert_days']
                              : []
                          "
                          @change="handleChangeRadio"
                        />
                        <DatePicker
                          v-if="
                            payload.alert_days &&
                            payload.alert_days === 'custom'
                          "
                          name="expiry_date"
                          :value="profile.expiry_date"
                          @input="handleDatePickerChange"
                          :error="errors[0]"
                          format="DD-MM-YYYY"
                          :showFromToday="true"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
                <CCol lg="6">
                  <CRow class="mb-3">
                    <label name="lbl_urgent" class="col-lg-12 col-md-12"
                      >Is Urgent?</label
                    >
                    <CCol md="12">
                      <RadioButton
                        name="is_urgent"
                        :value="profile.is_urgent"
                        :options="
                          options && options['boolean']
                            ? options['boolean']
                            : []
                        "
                        @change="handleChangeRadio"
                      />
                    </CCol>
                  </CRow>
                </CCol>
                <!-- <CCol lg="6">
                  <CRow class="mb-3">
                    <label class="col-lg-12 required">Priority Level</label>
                    <div class="col-lg-12">
                      <ValidationProvider rules="required" v-slot="{ errors }">
                        <Select
                          name="priority_level"
                          @input="handleChangeSelect"
                          :value="profile.priority_level"
                          :options="
                            options && options['priority_level']
                              ? options['priority_level']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                          :error="errors[0]"
                        />
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol> -->
              </CRow>
              <CRow>
                <CCol lg="8">
                  <CRow class="mb-3">
                    <div class="col-lg-12 col-md-12 d-flex pb-2">
                      <div>
                        <label class="text-nowrap pr-3 required">Comment</label>
                      </div>
                      <div
                        class="w-100"
                        v-if="options['commentsOptions'].length"
                      >
                        <Select
                          name="customComments"
                          :value="profile.customComments"
                          @input="handleChangeSelectCustom"
                          :options="
                            options && options['commentsOptions']
                              ? options['commentsOptions']
                              : []
                          "
                          :taggable="false"
                          :multiple="false"
                          :clearable="false"
                        />
                      </div>
                    </div>
                    <!-- <label class="col-lg-12" 
                      >Comments</label
                    > -->
                    <div class="col-lg-12">
                      <ValidationProvider
                        :rules="{ required: true }"
                        v-slot="{ errors }"
                      >
                        <TextAreaInput
                          name="comments"
                          :value="profile.comments"
                          label="comments"
                          @input="handleInput"
                          :error="errors[0]"
                        ></TextAreaInput>
                      </ValidationProvider>
                    </div>
                  </CRow>
                </CCol>
              </CRow>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </CModal>
  </div>
</template>
<script>
import Vue from "vue";
import m from "moment";
import Select from "@/components/reusable/Fields/Select.vue";
import TextInput from "@/components/reusable/Fields/TextInput";
import TextAreaInput from "@/components/reusable/Fields/TextareaInput";
import DatePicker from "@/components/reusable/Fields/DatePicker";
import RadioButton from "@/components/reusable/Fields/RadioButton";
import { extend } from "vee-validate";
import { mapActions, mapGetters } from "vuex";
import { isEmptyObjectCheck, isObject } from "@/helpers/helper";
import PhoneInputwithCountry from "@/components/reusable/Fields/PhoneInputwithCountry";
import AddExtraInterviewer from "@/components/reusable/GenericTabFilters/AddExtraInterviewer";
import { required, regex, min, numeric } from "vee-validate/dist/rules";
import _ from "lodash";
extend("numeric", { ...numeric, message: "Only numbers are allowed" });
extend("phone_rule", {
  ...regex,
  message: "Only numbers are allowed(Maximum of 15 digits)",
});

extend("required", { ...required, message: "This field is required" });
export default {
  props: {
    isShowPopup: {
      type: Boolean,
      default: false,
    },
    details: {
      type: Object,
      default: null,
    },
    action: {
      type: Object,
      default: null,
    },
    job: {
      type: Object,
      default: null,
    },
    candidate: {
      type: Object,
      default: null,
    },
  },
  components: {
    Select,
    TextInput,
    DatePicker,
    TextAreaInput,
    PhoneInputwithCountry,
    RadioButton,
    AddExtraInterviewer,
  },
  data() {
    return {
      profile: {},
      payload: {},
      sub_filter_list: { 57: 20, 60: 22 },
      showAddInterviewer: false,
    };
  },
  computed: {
    ...mapGetters([
      "getTabReasons",
      "getSubStatusOptions",
      "isFetchingFilter",
      "getInterviewSchedule",
      "dialingCodeWithCountryCode",
      "actionComments",
      "getCustomerId",
    ]),
    options() {
      return {
        sub_status: this.filteredSubStatus || [],
        reason_code: this.getTabReasons || [],
        priority_level: [
          { code: 1, label: "High" },
          { code: 2, label: "Medium" },
          { code: 3, label: "Low" },
        ],
        dialingCode: this.dialingCodeWithCountryCode || [],
        commentsOptions: this.actionComments || [],
        boolean: [
          { code: true, label: "Yes" },
          { code: false, label: "No" },
        ],
        alert_days: [
          { code: 1, label: "1 Day" },
          { code: 3, label: "3 Days" },
          { code: 7, label: "7 Days" },
          { code: "custom", label: "Customize" },
        ],
      };
    },
    isOtherReason() {
      return this.profile?.reason_id?.code === 100 || false;
    },
    sub_filter_id() {
      return this.sub_status_id && [57, 60].includes(this.sub_status_id)
        ? this.sub_filter_list[this.sub_status_id]
        : this.sub_status_id;
    },
    status() {
      if (
        isObject(this.details?.status) &&
        !isEmptyObjectCheck(this.details?.status)
      )
        return this.details?.status?.display_text;
      return this.details?.status;
    },
    sub_status() {
      if (
        isObject(this.details?.sub_status) &&
        !isEmptyObjectCheck(this.details?.sub_status)
      )
        return this.details?.sub_status?.display_text;
      return this.details?.sub_status;
    },
    isSubmissionOnlyAction() {
      return [14].includes(this.action?.action_id) || false;
    },
    isSubmissionAction() {
      return [14, 6].includes(this.action?.action_id) || false;
    },
    isBoardedAction() {
      return [1].includes(this.action?.action_id) || false;
    },
    isInterviewSchedule() {
      return this.profile?.sub_status_id?.code === 55 || false;
    },
    slots() {
      const dataMap = {
        slot_1: {
          available_slot1_date: "date",
          available_slot1_start: "start_time",
          available_slot1_end: "end_time",
        },
        slot_2: {
          available_slot2_date: "date",
          available_slot2_start: "start_time",
          available_slot2_end: "end_time",
        },
        slot_3: {
          available_slot3_date: "date",
          available_slot3_start: "start_time",
          available_slot3_end: "end_time",
        },
      };
      const res = this.getInterviewSchedule
        .filter((val) => val?.candidate_uid === this.details?.candidate_uid)
        .map((val) => {
          let slots = [];
          for (const key in dataMap) {
            const slotFields = dataMap[key];
            let slot = {};
            for (let [field, value] of Object.entries(slotFields)) {
              if (val[field]) {
                slot[value] = val[field];
              }
            }
            if (!isEmptyObjectCheck(slot)) {
              slot["name"] = _.startCase(key);
              slot["action_detail_id"] = val?.action_detail_id;
              slots = [...slots, slot];
            }
          }
          return slots;
        });
      return res.length ? res[0] : [];
    },
    filteredSubStatus() {
      const sub_status_id =
        this.details?.sub_status?.sub_status_id || this.details?.sub_status_id;
      return (
        this.getSubStatusOptions.filter(({ code }) => code != sub_status_id) ||
        []
      );
    },
    isInterviewRequest() {
      return this.profile?.sub_status_id?.code === 51 || false;
    },
    isShortlistAction() {
      return [12, 13].includes(this.action?.action_id) || false;
    },
  },
  methods: {
    ...mapActions([
      "showToast",
      "fetchStatusOptions",
      "fetchSubStatusOptions",
      "fetchJobCandidateReasonCode",
      "fetchInterviewSchedule",
      "initFetchContactInfoOptions",
      "fetchCommentsfor_recruiter",
      "fetchCandidateJoiningDate",
    ]),
    handleInput(name, value) {
      Vue.set(this.profile, name, value);
      Vue.set(this.payload, name, value);
    },
    handleChangeSelectCustom(name, value) {
      const comment = "comments";
      const v = value ? value.id || value.code || value.label || value : null;
      Vue.set(this.profile, comment, v);
      Vue.set(this.profile, name, v);
      this.payload = {
        ...this.payload,
        [comment]: v,
      };
    },
    handleChangeSelect(name, value) {
      Vue.set(this.profile, name, value);
      this.payload = {
        ...this.payload,
        [name]: value ? value.id || value.code || value : null,
      };
      if (["sub_status_id"].includes(name)) {
        const { action_id, label, filter_id, tab_id } = this.action;
        // this.fetchJobCandidateReasonCode({
        //   tab_id,
        //   filter_id,
        //   action_id,
        //   sub_status_id: this.payload["sub_status_id"],
        // }).then((res) => {
        //   if (this.getTabReasons.length === 1)
        //     this.handleChangeSelect("reason_id", this.getTabReasons[0]);
        // });
        if (this.payload[name] === 55) {
          const from_date = m().subtract(10, "days").format("YYYY-MM-DD");
          const to_date = m().add(10, "days").format("YYYY-MM-DD");

          const { candidate_uid, job_id } = this.details;

          this.fetchInterviewSchedule({
            order_by: "-action_detail_id",
            date_field: "initiated_date",
            // from_date,
            // to_date,
            completed_by__isnull: true,
            skip: 0,
            limit: 1,
            action_id: 18,
            job_id,
            candidate_uid,
          }).then((res) => {
            if (res && res.length && res[0]["interview_mode"] === "call") {
              const candidate_phone = res[0]?.candidate?.phone || null;
              this.handleInput("contact_phone", candidate_phone);
            }
          });
        } else {
          const deleteFields = [
            "action_detail_id",
            "selected_slot_date",
            "selected_slot_start",
            "selected_slot_end",
            "interview_link",
          ];
          deleteFields.forEach((element) => {
            delete this.payload[element];
          });
        }
        let { sub_status_id } = this.payload;
        const { status_id } = this.details;
        if (sub_status_id == 59) {
          sub_status_id = 57;
        }
        this.fetchCommentsfor_recruiter({ status_id, sub_status_id });
      }
    },
    handleChangeRadio(name, value) {
      let val = null;
      if (value?.id === false || value?.code === false) {
        val = false;
      } else {
        val = value?.id || value?.code || value;
      }
      Vue.set(this.profile, name, value);
      this.payload = {
        ...this.payload,
        [name]: val,
      };
      if (name === "alert_days" && val != "custom") {
        this.handleDatePickerChange("expiry_date", m().add(val, "d").toDate());
      }
    },
    handleRadioCustom(name, value) {
      Vue.set(this.profile, name, value);
      Vue.set(this.payload, name, value);
    },
    handleRadio(slot) {
      const { action_detail_id, date, start_time, end_time } = slot;
      this.payload = {
        ...this.payload,
        action_detail_id,
        selected_slot_date: date,
        selected_slot_start: start_time,
        selected_slot_end: end_time,
        expiry_date: `${date} ${end_time}`,
      };
    },
    modalCallBack(action, payload) {
      this.$emit("modalCallBack", action, payload);
    },
    async onSubmit() {
      const isValid = await this.$refs.candidateStatus.validate();
      if (!isValid) {
        this.showToast({
          class: "bg-danger text-white",
          message: "please fill mandatory fields!",
        });
        return;
      }
      let hasError = { hasError: false, errorMsg: "" };
      if (this.isInterviewSchedule) {
        if (
          !(
            this.payload["action_detail_id"] &&
            this.payload["selected_slot_date"]
          )
        ) {
          hasError.hasError = true;
          hasError.errorMsg = this.slots.length
            ? "Please select any one slot"
            : "please wait for slot schedule";
        }
      }
      if (this.isInterviewRequest) {
        if (
          !(
            this.payload["type_of_process"] &&
            this.payload["interview_mode"] &&
            this.payload["alloted_time"]
          )
        ) {
          hasError.hasError = true;
          hasError.errorMsg = !this.payload["type_of_process"]
            ? "Please Select Type of Interview Process"
            : !this.payload["interview_mode"]
            ? "please Select Style of Interview"
            : "Please Select Interview Duration";
        }
      }
      if (hasError.hasError) {
        this.showToast({
          class: "bg-danger text-white",
          message: hasError.errorMsg,
        });
      } else {
        const { action_id, filter_id, tab_id } = this.action;
        const { status_id: current_status, sub_status_id: current_sub_status } =
          this.details;
        const { sub_status_id } = this.payload;
        this.payload = {
          ...this.payload,
          action_id,
          filter_id,
          tab_id,
          current_status,
          current_sub_status,
        };
        if (action_id === 6 && sub_status_id === 55) {
          const external_interviewer_ids =
            await this.$refs?.addExtraInterviewer?.externalUser();
          if (external_interviewer_ids) {
            this.payload = { ...this.payload, external_interviewer_ids };
            this.modalCallBack(true, this.payload);
          }
        } else {
          this.modalCallBack(true, this.payload);
        }
      }
    },
    resetObject(obj) {
      for (const key in obj) {
        this.profile[obj] = null;
        this.payload[obj] = null;
      }
    },
    handleDatePickerChange(name, value) {
      Vue.set(this.profile, name, value);
      Vue.set(
        this.payload,
        name,
        value ? m(value).format("YYYY-MM-DD 00:00:00") : null
      );
    },
    openFrontSheet() {
      this.$emit("openFrontSheet", this.candidate, this.job);
    },
  },
  mounted() {
    const { action_id, label, filter_id, tab_id } = this.action;
    this.profile["action_id"] = { label, code: action_id };
    if (this.isSubmissionAction) {
      let alertDate = m().add(7, "d").toDate();
      this.handleDatePickerChange("expiry_date", alertDate);
      this.handleChangeRadio("alert_days", 7);
      // this.handleChangeSelect("priority_level", { code: 2, label: "Medium" });
      this.handleChangeRadio("is_urgent", false);
    }
    this.fetchSubStatusOptions({
      tab_id,
      filter_id,
      action_id,
    }).then((res) => {
      if (this.getSubStatusOptions.length === 1)
        this.handleChangeSelect("sub_status_id", this.getSubStatusOptions[0]);
    });
    // this.fetchJobCandidateReasonCode({ tab_id, filter_id, action_id });
    const { candidate_uid, job_id } = this.details;
    this.fetchCandidateJoiningDate({
      order_by: "-action_detail_id",
      date_field: "initiated_date",
      completed_by__isnull: true,
      skip: 0,
      limit: 1,
      action_id: 18,
      job_id,
      candidate_uid,
    }).then((res) => {
      if (res?.status === 200) {
        if (res?.data?.length) {
          const joining_date = res?.data[0]?.joining_date;
          if (joining_date) {
            const joining_date_obj = m(joining_date).toDate();
            this.handleDatePickerChange("joining_date", joining_date_obj);
          }
        }
      }
    });
    this.initFetchContactInfoOptions();
    this.$store.commit("RESET_COMMENTS");
  },
  filters: {
    formatDate(date) {
      if (date) return m(date).format("DD MMM YYYY");
      return "--";
    },
    formatTime(date) {
      if (date) {
        const dateObj = new Date();
        const dateSplit = date.split(":");
        dateObj.setHours(dateSplit[0], dateSplit[1], dateSplit[2]);
        return m(dateObj).format("hh:mm A");
      }
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped></style>
