<template>
  <div class="time-slot scroll-arrow">
    <div v-if="date">
    <a
      class="arrow left btn p-0"
      :class="{
        'theme-bg': pagination.currentPage,
        disabled: pagination.currentPage === 0,
      }"
      @click="
        pagination.currentPage === 0 ? true : (pagination.currentPage -= 1)
      "
      v-if="pagination.noOfPages > 1"
      :disabled="pagination.currentPage === 0"
    >
      <i class="fas fa-caret-left"></i>
    </a>
    <a
      class="arrow right btn p-0"
      :class="{
        'theme-bg': pagination.currentPage < pagination.noOfPages - 1,
        disabled: pagination.currentPage === pagination.noOfPages - 1,
      }"
      @click="
        pagination.currentPage === pagination.noOfPages - 1
          ? true
          : (pagination.currentPage += 1)
      "
      v-if="pagination.noOfPages > 1"
      :disabled="pagination.currentPage === pagination.noOfPages - 1"
    >
      <i class="fas fa-caret-right"></i>
    </a>
    <div class="scroll-wrapper">
      <div class="time-slot">
        <label
          v-for="(time, index) in timeSlots[pagination.currentPage]"
          :key="index"
          @click="setSlot(time)"
          class="mx-1"
        >
          <div class="item-container">
            <div class="item" :class="getSlotClass(time)">
              <span class="item-label" :name="`time-slot-${index}`"
                ><div>{{ time | formatTime }}</div></span
              >
            </div>
          </div>
        </label>
      </div>
    </div>
  </div>
  </div>
</template>
<script>
import _ from "lodash";
import moment from "moment";
import { isEmptyObjectCheck } from "../../../helpers/helper";
export default {
  props: {
    name: String,
    value: null,
    disabled: {
      type: Boolean,
      default: () => false,
    },
    startTime: {
      type: String,
      default: () => "00:00",
    },
    endTime: {
      type: String,
      default: () => "00:00",
    },
    date: {
      type: String,
      default: () => "",
    },
    interval: {
      type: Number,
      default: () => 30,
    },
    disabledTime: {
      type: Array,
      default: () => [],
    },
    duration: {
      type: Number,
      default: () => 30,
    },
    slotTimes: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      temp_value: null,
      slot: null,
      pagination: {
        noOfPages: 0,
        currentPage: 0,
      },
    };
  },
  computed: {
    timeSlots() {
      let startDate = moment(
        this.date + " " + this.startTime,
        "YYYY-MM-DD HH:mm"
      );
      const endDate = moment(
        this.date + " " + this.endTime,
        "YYYY-MM-DD HH:mm"
      );
      let times = [];
      while (startDate < endDate) {
        times.push(startDate.clone().toDate());
        startDate = startDate.add(this.interval, "m");
      }
      const res = this.paginate(times, 20);
      this.pagination["noOfPages"] = res.length;
      return res;
    },
  },
  methods: {
    paginate(arr, size) {
      return arr.reduce((acc, val, i) => {
        let idx = Math.floor(i / size);
        let page = acc[idx] || (acc[idx] = []);
        page.push(val);

        return acc;
      }, []);
    },
    getSlotClass(time) {
      return this.isAlreadySelected(time)
        ? "disabled"
        : this.isInOtherSlot(time)
        ? "another_slot"
        : this.isSelectedSlot(time)
        ? "selected"
        : "";
    },
    isSelectedSlot(time) {
      if (!_.has(this.slotTimes, this.name)) return false;
      const testTime = moment(time);
      const sltFTime = this.slotTimes[this.name].start;
      const sltTTime = moment(this.slotTimes[this.name].start).add(
        this.duration,
        "m"
      );
      return testTime.isSameOrAfter(sltFTime) && testTime.isBefore(sltTTime);
    },
    isAlreadySelected(time) {
      const testTime = moment(time);
      const res = this.disabledTime.some((e) => {
        const { start, end } = e;
        const startDate = moment(start, "YYYY-MM-DD HH:mm");
        const endDate = moment(end, "YYYY-MM-DD HH:mm");
        return testTime.isSameOrAfter(startDate) && testTime.isBefore(endDate);
      });
      return res;
    },
    isInOtherSlot(time) {
      const testTime = moment(time);
      const keys = Object.keys(this.slotTimes);

      const res = keys.some((key) => {
        const data = this.slotTimes[key];
        const { start, end } = data;
        const startDate = moment(start, "YYYY-MM-DD HH:mm");
        const endDate = moment(end, "YYYY-MM-DD HH:mm");
        return (
          key != this.name &&
          testTime.isSameOrAfter(startDate) &&
          testTime.isBefore(endDate)
        );
      });

      return res;
    },
    isUnSelectable(time) {
      const endTime = moment(time).add(this.duration, "m");

      const endTimer = moment(
        this.date + " " + this.endTime,
        "YYYY-MM-DD HH:mm"
      );
      return this.isAlreadySelected(endTime) || endTime > endTimer;
    },
    setSlot(value) {
      if (
        !this.isUnSelectable(value) &&
        !this.isAlreadySelected(value) &&
        !this.isInOtherSlot(value)
      ) {
        this.temp_value = value;
        const end = moment(value).add(this.duration, "m");
        const payload = { start: this.temp_value, end };
        this.$emit("change", this.name, payload);
        this.$emit("input", this.name, payload);
      }
    },
  },
  filters: {
    formatTime(date) {
      if (date) return moment(date).format("hh:mm A");
      return "--";
    },
  },
};
</script>
<style lang="scss" scoped>
.item-container {
  display: inline-block;
  padding-top: 5px;
  padding-right: 5px;
  vertical-align: top;
  cursor: pointer;
}
.item {
  display: inline-table;
  padding: 2px 0;
  border: 1px solid transparent;
  border-radius: 2px;
  color: #333;
  font-size: 14px;
}
.item-label {
  padding-right: 5px;
  white-space: pre-line;
  user-select: none;
  display: table-cell;
  padding: 0 5px;
  vertical-align: middle;
  box-sizing: border-box;
}
.disabled {
  cursor: not-allowed;
  // background: #f06f6f;
  // color: white;
  border: 1px solid #f06f6f;
  color: #f06f6f;
  border-radius: 2px;
}
.selected {
  // background: green;
  // color: white;
  border: 1px solid green;
  border-radius: 2px;
  color: green;
}
.another_slot {
  cursor: not-allowed;
  // background: #f0ce6f;
  // color: white;
  border: 1px solid #ffbf00;
  border-radius: 2px;
  color: #ffbf00;
}
.arrow.left {
  float: left;
}
.arrow.right {
  float: right;
}
a.arrow {
  width: 32px;
  height: 32px;
  background-color: $gray-light;
  color: #fff;
  line-height: 32px;
  text-align: center;
  border-radius: 50%;
  font-size: 1.5rem;
  background-color: #dd3651;
  cursor: pointer;
}
a.arrow:disabled,
a.arrow[disabled] {
  cursor: not-allowed;
  background-color: grey;
}
</style>